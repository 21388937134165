import React, { useEffect, useState } from 'react';
import SideNavBar from '../sidebar/sidebar';
import Navbar from '../navbar/navbar';
import { Container } from 'react-bootstrap';
import Main from '../main/main';
import Footer from '../footer/footer';
import ConsentForm from '../consentForm/ConsentForm';
import '../sidebar/sidebar.scss';

export default function Layout({ children }) {
    return (
        <>
            <ConsentForm />
            <Navbar></Navbar>
            <Container fluid>
                <Main>{children}</Main>
            </Container>
            <Footer />
        </>
    );
}
