import React, { useEffect, useState } from 'react';
import './Contact.scss';
import ContactCard from './ContactCard';
import api from '../../api';
import ContainerLoader from '../loader/container-loader';

function Contact() {

    const [data, setData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const groupData = (baseData) => {
        const finaldata = baseData
            .filter(item => item.issubdepartment === false) // Get Departments
            .map(parent => ({
                ...parent,
                subDepartments: baseData?.filter(item => item.parentid === parent.id) // Add subDepartments to each Department
                    .sort((a, b) => a.department.localeCompare(b.department)) // sort Departments
            })).sort((a, b) => a.department.localeCompare(b.department)) // sort subDepartments
        return finaldata;
    }

    const fetchDepartments = async () => {
        try {
            setIsFetching(true);
            const request = { resource: 'api/departments' };
            const response = await api.clientsinformation.getDetails(request);
            const filteredData = response?.data?.data?.res?.filter(item => (item.isdeleted === false));
            const groupedData = groupData(filteredData);
            setData(groupedData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchDepartments();
    }, []);

    return (<div className='contactContainer'>
        <h3 className='title my-4 ms-4'>Departments</h3>
        {isFetching ? <ContainerLoader /> :
            <div className='d-flex flex-wrap justify-content-center mb-5 mx-auto'>
                {data?.map((contact, index) => <React.Fragment key={index}>
                    <ContactCard baseData={contact} />
                </React.Fragment>)}
            </div>
        }
    </div>
    )
}

export default Contact
