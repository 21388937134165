import React from 'react';
import './LearningLounge.scss';

function QuickLinksCard({ data }) {
    return (
        <>
            {data[0]?.redirect_url ? <div className='text-center' title={data[0]?.title} onClick={() => { window.open(`${data[0]?.redirect_url}`, '_blank', 'noopener') }}>
                <img src={data[0]?.media_url} alt={data[0]?.title} className='quicklinkcardimage' />
            </div>
                :
                <div className='text-center' title={data[0]?.title}>
                    <img src={data[0]?.media_url} alt={data[0]?.title} className='quicklinkcardimage' />
                </div>
            }
        </>
    )
}

export default QuickLinksCard

