// import * as http from './http';
import ApiService from './service';
import * as http from './http';

export class WallofFameTypeService extends ApiService {

    async getDetails(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }
}
