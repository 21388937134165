import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './AudioPlayer.css';
import { MdClose } from "react-icons/md";
import { Button, Col, Row } from 'react-bootstrap';
import './LearningLounge.scss';

const AudioPlayerComponent = ({ playlist, setShow }) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

    const handleClickNext = () => {
        setCurrentTrackIndex((currentTrackIndex + 1) % playlist.length);
    };

    const handleClickPrevious = () => {
        setCurrentTrackIndex((currentTrackIndex - 1 + playlist.length) % playlist.length);
    };

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button className="btn btn-sm btn-primary float-right" onClick={() => { setShow(false) }}><MdClose /></Button>
            </div>
            <Row>
                <Col md={9} className='mt-2'>
                    <div>
                        <AudioPlayer
                            src={playlist[currentTrackIndex]?.media_url}
                            header={`Now playing: ${playlist[currentTrackIndex]?.title}`}
                            onClickNext={handleClickNext}
                            onClickPrevious={handleClickPrevious}
                            onEnded={handleClickNext}
                            showSkipControls
                            isPlaying={false}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        </div>

                    </div>
                </Col>
                <Col md={3}>
                    <div className='d-flex justify-content-between mt-2' style={{ backgroundColor: '#000', color: '#fff', borderRadius: '5px 5px 0px 0px' }}>
                        <div className='p-3 text-center'>Queue</div>
                    </div>
                    <ul className="playlist" style={{ overflow: 'scroll', height: '300px' }}>
                        {playlist.map((track, index) => (
                            <li
                                key={index}
                                onClick={() => setCurrentTrackIndex(index)}
                                className={currentTrackIndex === index ? 'active' : ''}
                            >
                                {track.title}
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default AudioPlayerComponent;
