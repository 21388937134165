import React, { useRef, useEffect, useState, useLayoutEffect, useCallback } from "react";
import { Col, Container, Row, Tooltip, Button, Popover } from "react-bootstrap";
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import api from '../../../api';
import './OrganizationChart.scss'
import ContainerLoader from "../../../components/loader/container-loader";
import { useSelector } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const boxStyle = {
    fontSize: '14px',
    border: "1px #999 solid",
    borderRadius: "10px",
    textAlign: "left",
    width: "200px",
    minHeight: "50px",
    color: "black",
    marginTop: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    paddingLeft: '10px',
    paddingRight: '10px'
}

const active = {
    border: "3px red solid",
}

const OrganizationChart = () => {
    const [employees, setEmployees] = useState([])
    const [employeesColumns, setEmployeesColumns] = useState([])
    const [isfetchingOrgInfo, setIsfetchingOrgInfo] = useState(false);
    const [isfetchingUserDetails, setIsfetchingUserDetails] = useState(false);
    const [nodesToHighlight, setNodesToHighlight] = useState([])
    const [nodesToDraw, setNodesToDraw] = useState([])
    const [userDetails, setUserDetails] = useState([]);
    const authState = useSelector((state) => state.authUser);
    const { profile } = authState;
    const userEmail = profile.email.toLowerCase();

    const [selectedDetails, setSelectedDetails] = useState([]);

    const findNodePath = (nodeId, node, path = []) => {
        if (node.employeeid === nodeId) {
            return [...path, node];
        }
        for (const child of node.direct_reports_details) {
            const result = findNodePath(nodeId, child, [...path, node]);
            if (result) {
                return result;
            }
        }
        return null;
    };

    const handleSelect = (id) => {
        const path = findNodePath(id, employees);
        if (path) {
            const details = path.map((node, index) => {
                const siblings = index === 0 ? [node] : path[index - 1].direct_reports_details.map(child => child);
                return { level: index, nodes: siblings };
            });

            const selectedNode = path[path.length - 1];
            const children = selectedNode.direct_reports_details.map(child => child);

            // Include children of the selected node in the details
            if (children.length > 0) {
                details.push({ level: path.length, nodes: children });
            }
            setSelectedDetails(details);
        }
    };

    const updateXArrow = useXarrow();

    function splitByLevel(employee) {
        const levels = {};

        // Helper function for depth-first traversal
        function traverse(employee, level) {
            if (!levels[level]) {
                levels[level] = []; // Create an array for the current level if it doesn't exist
            }

            levels[level].push(employee); // Add the current employee to the array for the current level

            // Recursively traverse the children of the current employee
            if (employee.direct_reports_details) {
                for (const child of employee.direct_reports_details) {
                    traverse(child, level + 1);
                }
            }
        }

        // Start traversal with the root employee (assuming it's at level 0)
        traverse(employee, 0);

        return Object.values(levels); // Return an array of arrays, each representing employees at a specific level
    }

    const fetchUserDetails = async () => {
        try {
            setIsfetchingUserDetails(true);
            const request = {
                resource: `api/zoho/getAllEmployees/${userEmail}`,
            };
            const userData = await api.ZohoService.getEmployeeByEmail(request);
            setUserDetails(userData.data.details[0]);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingUserDetails(false);
        }
    };


    const fetchOrgInfo = async () => {
        try {
            setIsfetchingOrgInfo(true);
            const request = { resource: 'api/zoho/getEmployessByReport' }
            const clientdata = await api.ZohoService.getEmployessByReport(request)
            setEmployees(clientdata.data.details)
            setEmployeesColumns(splitByLevel(clientdata.data.details));
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetchingOrgInfo(false);
        }
    }

    useEffect(() => {
        if (employeesColumns.length > 0) {
            findNodes(userDetails?.employeeid);
            handleSelect(userDetails?.employeeid);
        }
    }, [userDetails, employeesColumns])

    useEffect(() => {
        fetchUserDetails();
        fetchOrgInfo();
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", updateXArrow);
        return () => window.removeEventListener("scroll", updateXArrow);
    }, []);

    // Function to find parent list till root
    const findParents = (nodeId, data, parentList = []) => {
        for (let item of data) {
            if (item.employeeid === nodeId) {
                return parentList.concat(item);
            } else if (item.direct_reports_details.length > 0) {
                let result = findParents(nodeId, item.direct_reports_details, parentList.concat(item));
                if (result) {
                    return result;
                }
            }
        }
        return null; // Node not found
    }

    const findNodes = (nodeId) => {
        const currentelement = document.getElementById(nodeId);
        currentelement?.classList.add('activeElement');
        const list = findParents(nodeId, [employees])
        list?.map((item) => {
            // setTimeout(() => {
            // scrollToDiv(item?.employeeid)
            // }, 100);
        })
        const result = [];

        // Iterate over the list array
        for (let i = 0; i < list?.length - 1; i++) {
            // Construct objects with 'start' and 'end' properties
            const newObj = {
                start: list[i].employeeid,
                end: list[i + 1].employeeid
            };
            // Push the new object to the result array
            result.push(newObj);
        }
        result?.map((item) => {
            const element1 = document.getElementById(item.start);
            const element2 = document.getElementById(item.end);
            element1?.classList.add('activeElement');
            element2?.classList.add('activeElement');
        })
        setNodesToHighlight(result);
        const greyList = [];

        list?.map((node) => {
            if (node.direct_reports_details.length > 0) {
                node.direct_reports_details.map((innerNode) => {
                    const newObj = {
                        start: node.employeeid,
                        end: innerNode.employeeid
                    };
                    greyList.push(newObj);
                })
            }
        })
        setNodesToDraw(greyList)
    }

    const scrollToDiv = (divId) => {
        const currentElement = document.getElementById(`${divId}-div`);

        if (currentElement) {
            const scrollabaleDiv = document.getElementById(currentElement?.parentElement?.parentElement?.parentElement?.id);
            const li = document.getElementById(divId);
            const ulRect = scrollabaleDiv?.getBoundingClientRect();
            const liRect = li?.getBoundingClientRect();
            const offset = 100;
            const scrollTopPosition = scrollabaleDiv.scrollTop + liRect.top - ulRect.top - offset;

            scrollabaleDiv.scrollTo({ top: scrollTopPosition, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (nodesToHighlight.length > 0) {
            nodesToHighlight?.map((item) => {
                setTimeout(() => {
                    scrollToDiv(item?.start);
                    scrollToDiv(item?.end);

                }, 100);
            })
        }
    }, [nodesToHighlight])

    useEffect(() => {
        nodesToHighlight?.map((item) => {
            const element1 = document.getElementById(item.start);
            const element2 = document.getElementById(item.end);
            element1?.classList.add('activeElement');
            element2?.classList.add('activeElement');
        })
    }, [nodesToHighlight])

    const renderTooltip = (liItem) => <Popover className="popup-content">
        <Row className="tooltipBox">
            <Col className="empDetails">
                <div className="name" title={`${liItem.firstname} ${liItem.lastname }`}>{`${liItem.firstname} ${liItem.lastname }`}, {liItem.employeeid}</div>
                <div className="role">{liItem.title}</div>
                <div className="department" title={liItem.primary_department}>{liItem.primary_department}</div>
                <div className="emailid" title={liItem.emailid}>{liItem.emailid}</div>
            </Col>
            {liItem.total_employees > 0 && <Col className="countDetails">
                <div className="count">{liItem.total_employees}</div>
                <div className="totalMembers">Total Members</div>
                <div className="count">{liItem.direct_reports_count}</div>
                <div className="directReports">Direct reports</div>
            </Col>}
        </Row>
    </Popover>

    return (
        <div style={{
            // position: 'relative',
            width: '100%',
            height: '790px',
            overflowY: 'hidden',
            // overflowX: 'scroll'
            paddingBottom: '10px',
            paddingTop: '10px',
        }}>
            {(isfetchingOrgInfo || isfetchingUserDetails) ? <ContainerLoader /> :
                <Xwrapper>
                    <Row>
                        <Col xs={12} style={{ paddingTop: '5rem' }}>
                            <div className="d-flex overflow-x-scroll" onScroll={updateXArrow}>
                                {selectedDetails?.map((item, index) =>
                                    <div key={index} className="test">
                                        <ul className="ullist list-with-divs" id={`listdiv-${index}`} onScroll={updateXArrow}>
                                            {item?.nodes?.map((liItem, index) => <li key={index} id={liItem.employeeid} className={liItem.total_employees > 0 ? 'withEmp' : 'withoutEmp'} style={{ position: 'relative', overflow: 'hidden', listStyle: 'none', cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleSelect(liItem.employeeid);
                                                    const allelement = document.querySelectorAll('.list-with-divs li');
                                                    allelement.forEach((el) => { el.classList.remove('activeElement') });
                                                    findNodes(liItem.employeeid);
                                                }
                                                }>
                                                <div className="d-flex align-items-center">
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        data-toggle="popover"
                                                        delay={{ show: 1000, hide: 100 }}
                                                        overlay={renderTooltip(liItem)}
                                                    // trigger="click" 
                                                    >
                                                        <div style={{ ...boxStyle }} id={`${liItem.employeeid}-div`} className="empCountBox"><div className="boxName">{`${liItem.firstname} ${liItem.lastname }`}</div><div className="boxRole">{liItem.title}</div></div>
                                                    </OverlayTrigger>
                                                    {liItem?.total_employees > 0 && <div className="empCount border text-center p-1" style={{ width: '50px', position: 'relative', right: 0, marginLeft: '10px', height: '30px', top: '5px' }}>
                                                        {liItem?.total_employees}
                                                    </div>}
                                                </div>
                                                {liItem.total_employees > 0 ? <div className="hlinecount"></div> : <></>}
                                            </li>
                                            )}
                                        </ul>
                                    </div>)}
                                {(!isfetchingOrgInfo || !isfetchingUserDetails) && <div className="test-test" style={{ height: '790px' }} >
                                    {
                                        nodesToDraw?.map((item, index) => {
                                            return <Xarrow key={index} start={`${item.start}-div`} end={`${item.end}-div`} showHead={false} startAnchor={'right'} endAnchor={'left'} path={'grid'} zIndex={-2} color="#dadada" strokeWidth={1} />
                                        })
                                    }
                                    {
                                        nodesToHighlight?.map((item, index) => {
                                            return <Xarrow key={index} start={`${item.start}-div`} end={`${item.end}-div`} showHead={false} startAnchor={'right'} endAnchor={'left'} path={'grid'} zIndex={-1} color="#4b89dc" strokeWidth={2} />
                                        })
                                    }
                                </div>
                                }
                            </div>

                        </Col>
                    </Row>
                </Xwrapper>
            }
        </div>
    );
};

export default OrganizationChart;
