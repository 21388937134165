import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './ConsentForm.scss';
import { useSelector } from 'react-redux';
import api from '../../api';
import { toastr } from 'react-redux-toastr';

const ConsentForm = () => {
  const authState = useSelector((state) => state.authUser);

  const { profile } = authState;
  const userId = profile.user_id;

  const [showConsentForm, setShowConsentForm] = useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  function handleChange(e) {
    setIsChecked(e.target.checked);
  }

  const handleAccept = () => {
    const data = {
      isAcceptedTerms: true,
      acceptedDate: new Date().toISOString().slice(0, 19).replace('T', ' ')
    }
    handleAcceptSumit(data);
  };


  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const res = await api.user.getUserById(userId);
      if(res.data && res.data.data.isAcceptedTerms === true){
       setShowConsentForm(false);
      }else{
        setShowConsentForm(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptSumit = async (data) => {
    try {
      const request = { resource: `api/users/${userId}` };
      const res = await api.user.updateUser(request, {
        ...data,
      });
      if (res.data.status === 200) {
        toastr.success('Success', 'User updated successfully');
        setShowConsentForm(false);
      } else {
        toastr.error('Error', res.data.message);
        setShowConsentForm(true);
      }
    } catch (error) {
      toastr.error('Error', error.message);
      setShowConsentForm(true);
    }
  };

  return (
    <>
      <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={showConsentForm} className='consentModal'>
        <div className='consentForm'>
          <h2 className='termsHead'>Agilisium People Hub Platform Terms and Conditions</h2>
          <p className='consentText'>Welcome to Agilisium People Hub platform. By accessing and using this platform, you agree to comply with the following terms and conditions:</p>
          <p className='consentText'>Purpose: Agilisium people Hub platform is intended for internal use only. Its purpose is to facilitate communication, collaboration, and information sharing among employees of Agilisium consulting India Pvt, LLC, & INC.</p>
          <p className='consentText'>Access and Permissions: Access to the platform is limited to authorized employees of Agilisium consulting India Pvt, LLC, & INC. User permissions and roles are assigned by administrators and are subject to change.</p>
          <p className='consentText'>Acceptable Use: Users must refrain from posting or sharing content that is discriminatory, harassing, defamatory, or otherwise offensive. Users may not share confidential or proprietary information without proper authorization. Copyrighted material may not be shared on the platform without permission from the copyright owner. Spamming, trolling, or excessive self-promotion is prohibited, and action will be taken based on severity of such acts.</p>
          <p className='consentText'>Data Security and Privacy: Agilisium is committed to protecting the security and privacy of user data. User information will be collected, stored, and used in accordance with our Privacy Policy. Users are responsible for safeguarding their login credentials and ensuring the security of their accounts.</p>
          <p className='consentText'>Content Ownership and Copyright: Content posted on the intranet remains the property of Agilisium consulting India Pvt, LLC, & INC. Users must respect copyright laws and guidelines when sharing third-party content.</p>
          <p className='consentText'>Monitoring and Moderation: Communications on the platform may be monitored by administrators for compliance with policies. Administrators reserve the right to remove or edit content that violates these terms and conditions.</p>
          <p className='consentText'>User Responsibilities: Users are expected to communicate professionally and respectfully with fellow employees. Users should report any violations of these terms and conditions to administrators.</p>
          <p className='consentText'>Disclaimers: Agilisium makes no warranties regarding the accuracy or reliability of information shared on the platform. Opinions expressed by users are their own and do not necessarily reflect those of Agilisium consulting India Pvt, LLC, & INC.</p>
          <p className='consentText'>Updates and Changes: These terms and conditions may be updated or modified by Agilisium at any time. Users will be notified of any changes.</p>
          <p className='consentText'>Termination of Access: Access to the platform may be terminated if a user violates these terms and conditions or if their employment with Agilisium is terminated.</p>
          <p className='consentText'>Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of India, US, and Canada. Any disputes arising from these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India, US, and Canada respectively.</p>
          <p className='consentText'>Contact Information: For questions or concerns regarding these terms and conditions, please contact Hr@agilisium.com</p>
          <p className='consentText'>By using the intranet communications platform, you acknowledge that you have read, understood, and agree to abide by these terms and conditions.</p>
          <input className='consentText me-2' type="checkbox" id="topping" name="topping" checked={isChecked} onChange={handleChange} />I agree to these terms and conditions.
          <div className='mt-4'>
          <Button variant='primary' className='consentText' disabled={!isChecked} onClick={handleAccept}>Save</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConsentForm;
