import React from 'react';
import Input from './Input';
import { Label } from 'recharts';


const Radio = ({ options, name, alignAnswers, onChange }) => {

    const radioStyle = {
        display: 'flex',
        flexDirection: alignAnswers ? alignAnswers : 'row',
        justifyContent: 'flex-start',
        alignItems: alignAnswers === 'row' ? 'center' : 'start',
    };
    return (
        <div style={radioStyle}>
            {options.map((option, key) => (
                <Input
                    key={key}
                    type="radio"
                    id={option}
                    name={name}
                    value={option}
                    option={option}
                    onChange={onChange}
                />
            ))}
        </div>
    );
};

export default Radio;
