import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import knowledgeClips from "../../assets/logo/learningLounge/knowledgeClips.png";

function VideoContainer({playlist}) {
    const [show, setShow] = useState(false);

    return (
        <>
        <Modal
              className='momentsModal'
              show={show}
              size='xl'
              centered
              onHide={() => setShow(false)}
              keyboard={false}  // Prevents closing on escape key press
            >
              <Modal.Body>
                <VideoPlayer setShow={setShow} playlist={playlist} />
              </Modal.Body>
            </Modal>
        <div style={{ height: '100%', cursor: 'pointer' }} onClick={() => setShow(true)}>
            <img src={knowledgeClips} alt='knowledgeClips' style={{width:'250px', height: 'auto', objectFit:'contain'}} />
        </div>
        </>
    )
}

export default VideoContainer
