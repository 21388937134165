import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import  './DataTable.css'

const DataTables = ({ records, columns, pageSize=10}) => {
    


    const paginationOptions = {
        sizePerPageList: [{
            text: '5',
            value: 5
        }, {
            text: '10',
            value: 10
        }],
        hideSizePerPage: true,
        withFirstAndLast: false,
        sizePerPage: pageSize,
    };

    const rowClasses = (row, rowIndex) => {
        return 'customRow' // Add cursor pointer to rows
    };

    return (
        <div className="table-responsive">
            <BootstrapTable
                keyField='id'
                data={records}
                columns={columns}
                // defaultSorted={defaultSorted}
                pagination={paginationFactory({
                    ...paginationOptions,
                    paginationClassName: "pagination-container"
                })}
                
            />
        </div>
    );
};

export default DataTables;
