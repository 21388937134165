import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import NavRoutes from './routes/Routes';
// import ReactGA from 'react-ga';
// import usePageTracking from '../hooks/usePageTracking';

const App = () => {
    // const trackingID = process.env.REACT_APP_GOOGLE_TRACKING_ID
    // ReactGA.initialize(trackingID);
    // usePageTracking();
    
    return (
        <>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={true}
            />
            <NavRoutes />
        </>
    );
};

export default App;
