import React from 'react'
import { useEffect, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { formatDate } from '../../utils/formatDate';
import { MultiSelect } from 'react-multi-select-component';
import { debounce } from '../../utils/debounce';
import { useSelector } from 'react-redux';

const LearningLoungeTable = () => {
    const location = useLocation();
    const { state } = useLocation();
    const menuTypeId = location.state?.menuTypeId || null;
    const menu = location.state?.menu || null;

    const [data, setData] = useState([])
    const [sites, setSites] = useState([]);
    const [menuTypes, setMenuTypes] = useState([]);
    const [isfetching, setIsfetching] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [intialValues, setIntialValues] = useState();
    const [selectedLocations, setSelectedLocations] = useState([]);

    const navigate = useNavigate()

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const checkIsMediaRequired = (menuTypeId) => {
        if (menuTypeId === 1) {
            return false;
        } else {
            return true;
        }
    }


    const fetchLearningLoungeRecords = async (selectedLocations) => {
        try {
            setIsfetching(true)
            const request = { resource: 'api/learningLounge/' };
            const response = await api.learningLounge.getlearningLoungeByMenuType(request, menuTypeId);
            let filteredData = response.data.data.res;
            if (selectedLocations && selectedLocations.length > 0) {
                if (selectedLocations.includes('GLOBAL')) {
                    setData(filteredData);
                } else {
                    filteredData = response.data.data.res.filter(record => {
                        const recordLocations = record.siteaccessmulti
                            ? record.siteaccessmulti.map(location => location.value)
                            : []; // If siteaccessmulti is null or undefined, use an empty array
                        return selectedLocations.some(selected => recordLocations.includes(selected));
                    });
                    setData(filteredData);
                }

            }
            setData(filteredData)
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetching(false)
        }

    }

    const fetchLocationDetails = async () => {
        try {
            setIsfetching(true)
            const request = { resource: 'api/zoho/getLocation' }
            const response = await api.ZohoService.getLocation(request)
            const filteredSites = response.data.data.filter(loc => loc.country.trim() !== '');
            setSites(filteredSites)
        } catch (error) {
            console.log(error.message)
        }
    }

    const fetchMenuTypes = async () => {
        try {
            setIsfetching(true)
            const request = { resource: 'api/learningloungemenu' }
            const response = await api.learningLounge.getMenuTypes(request)
            const menuTypes = response.data.data;
            setMenuTypes(menuTypes)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchLocationDetails();
        fetchLearningLoungeRecords([]);
        fetchMenuTypes();
    }, [])


    const getStatusFromRow = (row) => {
        const isActive = row.isactive ? "Active" : "";
        const isInActive = row.isinactive ? "Inactive" : "";

        // Concatenate the statuses with a comma
        const status = [isActive, isInActive].filter(Boolean).join(", ");
        return status || "Unknown";
    };

    const getMediaTypeFromRow = (row) => {
        switch (row.media_type) {
            case 'image':
                return 'Image';
            case 'audio':
                return 'Audio';
            case 'video':
                return 'Video';
            default:
                return 'Unknown';
        }
    };
    
    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        checkIsMediaRequired(menuTypeId) &&
        {
            dataField: 'media_url',
            text: 'Media URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        checkIsMediaRequired(menuTypeId) && 
        {
            dataField: 'media_type',
            text: 'Media Type',
            sort: true,
            formatter: (cellContent, row) => {
                const status = getMediaTypeFromRow(row);
                return status;
            }

        },
        {
            dataField: 'redirect_url',
            text: 'Redirect URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'publishdate',
            text: 'Publish Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.publishdate);
            }
        },
        {
            dataField: 'expirydate',
            text: 'Expiry Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.expirydate);
            }
        },
        {
            dataField: 'isactive',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => {
                const status = getStatusFromRow(row);
                return status;
            }

        },
        {
            dataField: 'siteaccessmulti',
            text: 'Location',
            sort: true,
            formatter: (cell, row) => {
                if (row.siteaccessmulti && row.siteaccessmulti.length > 0) {
                    return row.siteaccessmulti.map(item => item.value).join(', ');
                } else {
                    return '';
                }
            }
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ].filter(Boolean);

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetching(true);
            const request = { resource: `api/learningLounge/${row.id}` };
            const response = await api.learningLounge.softDeleteRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetching(false);
        }
    }

    const handleEdit = (row) => {
        const hasNullField = [
            row.title,
            row.publishdate,
            row.expirydate,
            row.siteaccessmulti,
        ].some(field => field === null || field === '');

        // If any field is null, show error and return from the function
        if (hasNullField) {
            toastr.error('Missing Required Fields!');
            return;
        }
        setIntialValues(row)
        setCurrentId(row.id);
        navigate('/learningLoungeForm', { state: { menu, menuTypeId, data, sites, currentId: row.id, intialData: row, isEdit: true } })
    };

    const handleNew = () => {
        navigate('/learningLoungeForm', { state: { menu, menuTypeId, data, sites, currentId, isEdit: false } })
    }

    const debouncedFetchWallOfFlashRecords = useCallback(
        debounce((selectedCountries) => {
            fetchLearningLoungeRecords(selectedCountries);
        }, 2000), // Adjust the delay 
        []
    );

    const handleLocationChange = (selected) => {
        const selectedCountries = selected.map(location => location.value);
        if (selectedCountries.includes('GLOBAL')) {
            const allLocations = sites.map(loc => ({ value: loc.country, label: loc.country }));
            setSelectedLocations([{ value: 'GLOBAL', label: 'GLOBAL' }, ...allLocations]);
            debouncedFetchWallOfFlashRecords(['GLOBAL']);
        } else {
            setSelectedLocations(selected);
            debouncedFetchWallOfFlashRecords(selectedCountries);
        }
    };

    useEffect(() => {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, [])

    return (
        <div className={styles.fameContainer} style={{ height: 'auto'}}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
                <h4 className="my-4 managePageTitle">Manage {menu}</h4>
                {!isfetching && <div className={`${styles.filtersWrap} d-flex align-items-center  mt-3 mb-1`}>
                    <MultiSelect
                        className={`${styles.multiSelect} me-3`}
                        options={[
                            { value: 'GLOBAL', label: 'GLOBAL' }, // Option for selecting all locations
                            ...sites.map(loc => ({ value: loc.country, label: loc.country })) // Options for individual locations
                        ]}
                        value={selectedLocations}
                        onChange={handleLocationChange}
                        labelledBy='Select Location'
                        disableSearch={true}
                        hasSelectAll={false}
                        shouldCloseOnSelect={false}
                        selectAllLabel='GLOBAL'
                        overrideStrings={{
                            selectSomeItems: "Select Location", // Custom placeholder text
                        }}
                    />
                    <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                        Add New
                    </Button>
                    <Button className={`${styles.addRecords} mx-1`} onClick={()=>navigate('/managelearninglounge')} variant="primary">
                        Go Back
                    </Button>
                </div>}
            </div>
            {isfetching && <ContainerLoader />}
            <div className="walloffame_wrap mb-5">
                {data && data?.length > 0 && !isfetching && (<div className='row'>
                    <div className='col-12'>
                        <div className={`${styles.dataTableWrapper} mx-auto`}>
                            <DataTables records={data} columns={columns} />
                        </div>
                    </div>
                </div>)}

                {data?.length === 0 && !isfetching && <Alert variant='danger' className='mx-auto' style={{ width: '90%' }}>No Records Found!!!</Alert>}

            </div>
        </div>
    )
}

export default LearningLoungeTable

