import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DepartmentsForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const currentId = location.state?.currentId || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;
    const [isSubDepartmentChecked, setIsSubDepartmentChecked] = useState(false);
    const parents = location.state?.data?.filter(item => item.id !== currentId && item.issubdepartment === false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'issubdepartment') {
                    const isChecked = intialData.issubdepartment === true;
                    setValue('issubdepartment', isChecked);
                    setIsSubDepartmentChecked(isChecked);
                } else if (key === 'parentid') {
                    const parentid = intialData.parentid
                    setValue('parentid', parentid);
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);

    const handleCheckboxChange = (e) => {
        setIsSubDepartmentChecked(!isSubDepartmentChecked);
        setValue('parentid', null);
    };


    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false);
                const request = { resource: `api/departments/${currentId}` };
                const editResponse = await api.departments.updateDetails(request, {
                    ...formData,
                })

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/manageDepartments')
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/departments' };
                setIsfetching(true);
                setShowForm(false);
                const res = await api.departments.addDetails(request, {
                    ...formData,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/manageDepartments')

                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true);

    };

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])


    return (
        <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
            <h4 className="my-4 pagetitle">Departments Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Department</Form.Label>
                                    <Form.Control
                                        name="department"
                                        type='text'
                                        {...register('department', { required: 'Department is required' })}
                                    />
                                    {errors.department && <p style={{ color: 'red' }}>{errors.department.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type='text'
                                        {...register('email')}
                                    />
                                    {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                                </Form.Group>
                            </div>

                            <div className='col-12 col-md-6 d-flex flex-row'>
                                <div className='col-12 col-md-6 p-2'>
                                    <Form.Group>
                                        <Form.Label>Is Sub-Department</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            label="Is Sub-Department"
                                            name="issubdepartment"
                                            {...register('issubdepartment')}
                                            checked={isSubDepartmentChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        {errors.issubdepartment && (
                                            <p style={{ color: 'red' }}>{errors.issubdepartment.message}</p>
                                        )}
                                    </Form.Group>
                                </div>
                                {isSubDepartmentChecked && (<div className='col-12 col-md-6 p-2'>
                                    <Form.Group>
                                        <Form.Label>Select Parent</Form.Label>
                                        <Form.Select
                                            name="parentid"
                                            {...register('parentid', { required: 'Parent is required' })}

                                            className="mb-3"
                                            aria-label="Default select example"
                                            placeholder="Select Parent"
                                        >
                                            <option value=''>Select Parent</option>
                                            {parents.map((item, index) => <option key={index} value={item.id}>{item.department}</option>)}
                                        </Form.Select>
                                        {errors.parentid && <p style={{ color: 'red' }}>{errors.parentid.message}</p>}
                                    </Form.Group>
                                </div>)}
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>SPOC</Form.Label>
                                    <Form.Control
                                        name="spoc"
                                        type='text'
                                        {...register('spoc', { required: 'SPOC is required' })}
                                    />
                                    {errors.spoc && <p style={{ color: 'red' }}>{errors.spoc.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-end col-12 p-2'>
                                <Button variant="secondary" className='me-2' onClick={() => navigate('/manageDepartments')}>
                                    Cancel
                                </Button>
                                <Button type="reset" variant="secondary" className='mx-2' onClick={() => { reset(); setIsSubDepartmentChecked(false) }}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className='ms-2'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default DepartmentsForm;

