import React from "react";

const inputStyle = {
  border: "1px solid #00000024",
  borderRadius: "2px",
  height: "2em",
  padding: "5px",
  fontSize: "0.9em",
  outline: "none",
};

const Input = ({ type, option, name, onChange, disabled=false }) => {
  return (
    <React.Fragment>
      <input 
        type={type} 
        name={name} 
        id={option}
        value={option}
        style={inputStyle}
        onFocus={(e) => e.target.style.outline = "none"}
        onBlur={(e) => e.target.style.outline = "none"}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={option}>{option}</label>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </React.Fragment>
  );
};

export default Input;
