import { useEffect, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import api from '../../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import { Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTables from '../DataTables/DataTable';
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import './ManageAgilisiumInsights.scss';
import { formatDate } from '../../utils/formatDate';
import {MultiSelect} from 'react-multi-select-component';
import { debounce } from '../../utils/debounce';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import Alert from 'react-bootstrap/Alert';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';

function ManageAgilisiumInsights() {

    const navigate = useNavigate();
    const [isfetching, setIsfetching] = useState(false);
    const [data, setData] = useState([]);
    const [sites, setSites] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const userCountry = localStorage.getItem('userCountry');
    const [selectedLocations, setSelectedLocations] = useState([]);

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const getStatusFromRow = (row) => {
        const isActive = row.isactive ? "Active" : "";
        const isArchived = row.isarchived ? "Archived" : "";
        const isInactive = row.isinactive ? "Inactive" : "";
        const status = [isActive, isArchived, isInactive].filter(Boolean).join(", ");
        return status || "Unknown";
    };


    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className="ellipsisColumn" title={cellContent}>{cellContent}</div>);
            }    
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className="ellipsisColumn" title={cellContent}>{cellContent}</div>);
            }    
        },
        {
            dataField: 'imageurl',
            text: 'Image URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className="ellipsisColumn" title={cellContent}>{cellContent}</div>);
            }    
        },
        {
            dataField: 'redirecturl',
            text: 'Redirect URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className="ellipsisColumn" title={cellContent}>{cellContent}</div>);
            }    
        },
        {
            dataField: 'publishdate',
            text: 'Publish Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.publishdate);
            }
        },
        {
            dataField: 'expirydate',
            text: 'Expiry Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.expirydate);
            }
        },
        {
            dataField: 'isactive',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => {
                return getStatusFromRow(row);;
            }
        },
        // {
        //     dataField: 'siteaccess',
        //     text: 'Location',
        //     sort: true,
        // },
        {
            dataField: 'siteaccessmulti',
            text: 'Location',
            sort: true,
            formatter: (cell, row) => {
                if (row.siteaccessmulti && row.siteaccessmulti.length > 0) {
                    return row.siteaccessmulti.map(item => item.value).join(', ');
                } else {
                    return '';
                }
            }
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => editButtonClick(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ];

    //fetch Insights data from db
    const fetchInsights = async (selectedLocations) => {
        try {
            setIsfetching(true);
            const request = { resource: 'api/insights' };
            const response = await api.insights.getInsights(request);
            let filteredData =  response?.data?.data?.res;
            if (selectedLocations && selectedLocations.length > 0) {
                if (selectedLocations.includes('GLOBAL')) {
                    setData(filteredData);
                } else {
                    filteredData = response.data.data.res.filter(record => {
                        const recordLocations = record.siteaccessmulti
                            ? record.siteaccessmulti.map(location => location.value)
                            : []; // If siteaccessmulti is null or undefined, use an empty array
                        return selectedLocations.some(selected => recordLocations.includes(selected));
                    });
                    setData(filteredData);
                }
               
            }
            setData(filteredData);
    
        } catch (error) {
            console.log(error);
        } finally {
            setIsfetching(false);
        }
    };

    const fetchLocationDetails = async ()=>{
        try {
            setIsfetching(true);
            const request = {resource: 'api/zoho/getLocation'};
            const response = await api.ZohoService.getLocation(request);
            const filteredSites = response.data.data.filter(loc => loc.country.trim() !== '');
            setSites(filteredSites);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetching(false);
        }
    }

    useEffect(() => {
        fetchLocationDetails();
        fetchInsights([]);
    }, [])


    const editButtonClick = (row) => {
        const hasNullField = [
            row.title,
            row.description,
            row.imageurl,
            row.redirecturl,
            row.publishdate,
            row.expirydate,
            row.siteaccessmulti,
        ].some(field => field === null || field === '');

    // If any field is null, show error and return from the function
    if (hasNullField) {
        toastr.error('Missing Required Fields!');
        return;
    }
        setCurrentId(row.id);
        navigate('/insightsForm', { state: { data, sites, currentId:row.id, intialData:row, isEdit: true } } )
    };

    const handleAddRecordClick = () =>{
        navigate('/insightsForm', { state: { data, sites, currentId, isEdit: false } } )
    }

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetching(true)
            const request = { resource: `api/insights/${row.id}` };
            const response = await api.insights.softDeleteRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                 toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally{
            setIsfetching(false);
        }
    }

    const debouncedFetchInsigntsRecords = useCallback(
        debounce((selectedCountries) => {
            fetchInsights(selectedCountries);
        }, 2000), // Adjust the delay 
        []
    );

    const handleLocationChange = (selected) => {
        const selectedCountries = selected.map(location => location.value);
        // setSelectedLocations(selected)
        if (selectedCountries.includes('GLOBAL')) {
            const allLocations = sites.map(loc => ({ value: loc.country, label: loc.country }));
            setSelectedLocations([{ value: 'GLOBAL', label: 'GLOBAL' }, ...allLocations]);
            debouncedFetchInsigntsRecords(['GLOBAL']);
        } else {
            setSelectedLocations(selected);
            debouncedFetchInsigntsRecords(selectedCountries);
        }
        // fetchWallOfFameRecords(selectedCountries);
    };

    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []); 

    return (
        <div className={`${styles.fameContainer}`} style={{minHeight:'800px'}}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
                <h4 className="my-4 managePageTitle">Manage Agilisium Insights</h4>
                {!isfetching && <div className={`${styles.filtersWrap} d-flex align-items-center  mt-3 mb-1`}>
                <MultiSelect
                    className={`${styles.multiSelect} me-3`}
                    options={[
                        { value: 'GLOBAL', label: 'GLOBAL' }, // Option for selecting all locations
                        ...sites.map(loc => ({ value: loc.country, label: loc.country })) // Options for individual locations
                    ]}
                    value={selectedLocations}
                    onChange={handleLocationChange}
                    labelledBy='Select Location'
                    disableSearch={true}
                    hasSelectAll={false}
                    shouldCloseOnSelect={false}
                    selectAllLabel='GLOBAL'
                    overrideStrings={{
                        selectSomeItems: "Select Location", // Custom placeholder text
                    }}
                    />
                    <Button className={styles.addRecords} onClick={handleAddRecordClick} variant="primary">
                        Add New
                    </Button>
                </div>}
            </div>
            {isfetching && <ContainerLoader/>}
            <div className="dataTableWrapper mx-auto">
                {(!isfetching && data?.length > 0) && <DataTables records={data} columns={columns} />}
            </div>
            {data?.length === 0 && !isfetching && <Alert variant='danger' className='mx-auto' style={{width:'90%'}}>No Records Found!!!</Alert>}
        </div>
    );
}

export default ManageAgilisiumInsights
