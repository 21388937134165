import React from "react";
import "./footer.scss";
import { useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();

    return (<>
        <div className="footer-section">
            <div className="copyrightSection">
                <span className="copyrightText">© 2024. Agilisium Consulting India Pvt Ltd, LLC, Inc. | </span>
                <span className="copyrightText" style={{cursor: 'pointer'}} onClick={()=>{ navigate('/privacypolicy') }}>Privacy Policy | </span>
                <span className="copyrightText" style={{cursor: 'pointer'}} onClick={()=>{ navigate('/termsandconditions') }}>Terms and Conditions</span>
            </div>
        </div>
    </>
    );
}
