import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ContainerLoader from '../loader/container-loader';
import { useSelector } from 'react-redux';

function SurveyHome() {

    const [isdone, setIsDone] = useState(false);
    const [isFetchingSurvey, setIsFetchingSurvey] = useState(false);
    const [surveyDetails, setSurveyDetails] = useState([]);
    const authState = useSelector((state) => state.authUser);
    const { profile } = authState;
    const userEmail = profile.email.toLowerCase();

    const navigate = useNavigate();
    const location = useLocation();

    const poll_id = location.state?.pollId || {};

    const fetchSurveyDetails = async () => {
        try {
            setIsFetchingSurvey(true);
            // const request = { resource: `api/survey/getSurvey/${userEmail}` };
            // const surveyData = await api.Survey.getSurveyByUserMail(
            //     request,
            // );
            // const entries = surveyData.data.details.entries;
            // setSurveyDetails(entries);

            setTimeout(() => {
                setSurveyDetails(mockpolldata.data.res);
                setIsFetchingSurvey(false);
            }, 3000);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsFetchingSurvey(false);
        }
    };

    useEffect(() => {
        fetchSurveyDetails()
    }, [])



    return (<>
        {isFetchingSurvey ? <><ContainerLoader height={'100px'} /></> :
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/survey', { state: { pollId: { poll_id } } })}>
                <div className="sildebarHeader pb-4">
                    <span>Survey</span>
                    {!isdone ? <span className={`badge rounded-circle p-2 border border-white statusBusy`} style={{ height: '10px', width: '10px' }}>{' '}</span> : <></>}
                </div>
                <div style={{ textAlign: 'center' }}>{surveyDetails[0]?.name}</div>
            </div>}
    </>)
}

export default SurveyHome

export const mockpolldata={
    "status": 200,
    "message": "Records fetched successfully!",
    "pages": null,
    "data": {
        "res": [
            {
                "id": 2,
                "name": "Employee Details 2",
                "description": "Need Employee Details mentioned below Please",
                "startdate": "2024-09-05T00:00:00.000Z",
                "enddate": "2024-09-15T00:00:00.000Z",
                "displayresponse": true,
                "isactive": true,
                "isdeleted": false,
                "createdat": "2024-09-05T11:46:48.751Z",
                "createdby": null,
                "updatedat": "2024-09-05T11:46:48.751Z",
                "updatedby": null,
                "poll_questions": [
                    {
                        "id": 2,
                        "pollid": 2,
                        "question": "Employee Gender",
                        "questiontype": 5,
                        "displayorder": 2,
                        "ismandatory": true,
                        "ismultiSelect": true,
                        "createdat": "2024-09-05T12:00:05.018Z",
                        "updatedat": "2024-09-05T12:00:05.018Z",
                        "poll_question_options": [
                            {
                                "id": 1,
                                "pollquestionsid": 2,
                                "options": "Female",
                                "optiontype": 5,
                                "iscommentsrequired": false,
                                "createdat": "2024-09-05T12:10:07.674Z",
                                "updatedat": "2024-09-05T12:10:07.674Z"
                            },
                            {
                                "id": 2,
                                "pollquestionsid": 2,
                                "options": "Male",
                                "optiontype": 5,
                                "iscommentsrequired": false,
                                "createdat": "2024-09-05T12:10:24.654Z",
                                "updatedat": "2024-09-05T12:10:24.654Z"
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "pollid": 2,
                        "question": "Employee Name",
                        "questiontype": 1,
                        "displayorder": 1,
                        "ismandatory": true,
                        "ismultiSelect": false,
                        "createdat": "2024-09-05T11:59:24.271Z",
                        "updatedat": "2024-09-05T11:59:24.271Z",
                        "poll_question_options": []
                    }
                ]
            },
            {
                "id": 1,
                "name": "Employee Details 1",
                "description": "Need Employee Details mentioned below Please",
                "startdate": "2024-09-05T00:00:00.000Z",
                "enddate": "2024-09-15T00:00:00.000Z",
                "displayresponse": true,
                "isactive": true,
                "isdeleted": false,
                "createdat": "2024-09-05T11:46:48.751Z",
                "createdby": null,
                "updatedat": "2024-09-05T11:46:48.751Z",
                "updatedby": null,
                "poll_questions": [
                    {
                        "id": 2,
                        "pollid": 2,
                        "question": "Employee Gender",
                        "questiontype": 5,
                        "displayorder": 2,
                        "ismandatory": true,
                        "ismultiSelect": true,
                        "createdat": "2024-09-05T12:00:05.018Z",
                        "updatedat": "2024-09-05T12:00:05.018Z",
                        "poll_question_options": [
                            {
                                "id": 1,
                                "pollquestionsid": 2,
                                "options": "Female",
                                "optiontype": 5,
                                "iscommentsrequired": false,
                                "createdat": "2024-09-05T12:10:07.674Z",
                                "updatedat": "2024-09-05T12:10:07.674Z"
                            },
                            {
                                "id": 2,
                                "pollquestionsid": 2,
                                "options": "Male",
                                "optiontype": 5,
                                "iscommentsrequired": false,
                                "createdat": "2024-09-05T12:10:24.654Z",
                                "updatedat": "2024-09-05T12:10:24.654Z"
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "pollid": 2,
                        "question": "Employee Name",
                        "questiontype": 1,
                        "displayorder": 1,
                        "ismandatory": true,
                        "ismultiSelect": false,
                        "createdat": "2024-09-05T11:59:24.271Z",
                        "updatedat": "2024-09-05T11:59:24.271Z",
                        "poll_question_options": []
                    }
                ]
            }
        ]
    }
}

export const questioOptionTypes = [
    { key: '1', value: 'Text' },
    { key: '2', value: 'Text Area' },
    { key: '3', value: 'Number' },
    { key: '4', value: 'Date' },
    { key: '5', value: 'Radio' },
    { key: '6', value: 'Select' },
    { key: '7', value: 'Multi Select' },
    { key: '8', value: 'Checkbox' },

]