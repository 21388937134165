import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';
import { useSelector } from 'react-redux';

const SurveyMasterForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const currentId = location.state?.currentId || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'startdate') {
                    const formattedStartDate = formatDate(intialData.startdate);
                    setValue('startdate', formattedStartDate);
                } else if (key === 'enddate') {
                    const formattedEndDate = formatDate(intialData.enddate);
                    setValue('enddate', formattedEndDate);
                } else if (key === 'isactive') {
                    setValue('status_poll', intialData.isactive ? '1' : '2');
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);


    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/pollmaster/${currentId}` };
                const editResponse = await api.poll.updatePollMaster(request, {
                    ...formData,
                    isactive: formData.status_poll === '1' ? true : false,
                })

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/managesurvey')
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/pollmaster' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.poll.addPollMasterDetails(request, {
                    ...formData,
                    isactive: formData.status_poll === '1' ? true : false,
                    displayresponse: true
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/managesurvey')

                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)

    };

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])


    return (
        <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
            <h4 className="my-4 pagetitle">Survey Master Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-12 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type='text'
                                        {...register('name', { required: 'Title is required' })}
                                    />
                                    {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-12 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        {...register('description', { required: 'Description is required' })}
                                    />
                                    {errors.description && <p style={{ color: 'red' }}>{errors.description.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-4 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        // onChange={handleChange}
                                        type="date"
                                        placeholder="Start Date"
                                        name="startdate"
                                        {...register('startdate', { required: 'Start Date is required' })}

                                    />
                                    {errors.startdate && <p style={{ color: 'red' }}>{errors.startdate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-4 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control
                                        // onChange={handleChange}
                                        type="date"
                                        placeholder="End Date"
                                        name="enddate"
                                        {...register('enddate', { required: 'End Date is required' })}

                                    />
                                    {errors.enddate && <p style={{ color: 'red' }}>{errors.enddate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-4 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select
                                        name="status_poll"
                                        {...register('status_poll', { required: 'Status is required' })}

                                        className="mb-3"
                                        aria-label="Default select example"
                                        placeholder="Select Type"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </Form.Select>
                                    {errors.status_poll && <p style={{ color: 'red' }}>{errors.status_poll.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-end col-12 p-2'>
                                <Button variant="secondary" className='me-2' onClick={() => navigate('/managesurvey')}>
                                    Cancel
                                </Button>
                                <Button type="reset" variant="secondary" className='mx-2' onClick={() => reset()}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className='ms-2'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default SurveyMasterForm;
