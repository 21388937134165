const getNameFromEmail = (email) => {
    if (!email) return ''; // Handle missing email case

    const [namePart] = email.split('@');
    const nameParts = namePart.split('.'); // Assuming email is in the format first.last@domain.com

    if (nameParts.length >= 2) {
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' '); // In case there are middle names
        return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${lastName.charAt(0).toUpperCase() + lastName.slice(1)}`;
    }
    
    return namePart.charAt(0).toUpperCase() + namePart.slice(1); // Fallback to the full namePart capitalized
};

export default getNameFromEmail;