import { useEffect,useState,useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from './wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { formatDate } from '../../utils/formatDate';
import {MultiSelect} from 'react-multi-select-component';
import { debounce } from '../../utils/debounce';
import getNameFromEmail from '../../utils/nameExtract';
import { useSelector } from 'react-redux';

export default function WallOfFame() {
    const [types, setTypes] = useState([]);
    const [data, setData] = useState([]);
    const [sites, setSites] = useState([]);
    const [isfetching, setIsfetching] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [intialValues, setIntialValues] = useState();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isfetchingWallOfType, setIsfetchingWallOfType] = useState(false);
    const [isfetchingLocation, setIsfetchingLocation] = useState(false);
    const [isfetchingRecords, setIsfetchingRecords] = useState(false);
    const [isfetchingClients, setIsfetchingClients] = useState(false);
    const [clients, setClients] = useState(false);

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const navigate = useNavigate();

    useEffect(() => {
        const shouldFetch = isfetchingRecords || isfetchingWallOfType || isfetchingLocation || isfetchingClients;
        if (isfetching !== shouldFetch) {
            setIsfetching(shouldFetch);
        }
    }, [isfetchingRecords, isfetchingWallOfType, isfetchingLocation, isfetchingClients]);
    
    const fetchClients = async () => {
        try {
            setIsfetchingClients(true);
            const request = { resource: 'api/clientsinformation' };
            const response = await api.clientsinformation.getDetails(request)
            const filteredData = response.data.data.res.filter(item => (item.isdeleted === false))
            setClients(filteredData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingClients(false);
        }
    }

    const fetchWallOfType = async ()=>{
        try {
            setIsfetchingWallOfType(true);
            const request = {resource : 'api/walloftype'}
            const walloftype_Data = await api.WallofFameTypeService.getDetails(request)
            const data = walloftype_Data.data.data.res;
            setTypes(data);
            setIsfetchingWallOfType(false);        
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetchingWallOfType(false);
        }
    }

    const fetchWallOfFameRecords = async (selectedLocations)=>{
        try {
            setIsfetchingRecords(true);
            const request = {resource : 'api/walloffame'}
            const walloftype_Data = await api.walloffame.getDetails(request)
            let filteredData =  walloftype_Data.data.data;
            if (selectedLocations && selectedLocations.length > 0) {
                if (selectedLocations.includes('GLOBAL')) {
                    setData(filteredData);
                } else {
                    filteredData = walloftype_Data.data.data.filter(record => {
                        const recordLocations = record.siteaccessmulti
                            ? record.siteaccessmulti.map(location => location.value)
                            : []; // If siteaccessmulti is null or undefined, use an empty array
                        return selectedLocations.some(selected => recordLocations.includes(selected));
                    });
                    setData(filteredData);
                }
            }
            setData(filteredData)
            setIsfetchingRecords(false);
        } catch (error) {
            console.log(error.message);
        } finally{
            setIsfetchingRecords(false);
        }
    }

    const fetchLocationDetails = async ()=>{
        try {
            setIsfetchingLocation(true);
            const request = {resource: 'api/zoho/getLocation'}
            const response = await api.ZohoService.getLocation(request)
            const filteredSites = response.data.data.filter(loc => loc.country.trim() !== '');
        
            setSites(filteredSites)
            setIsfetchingLocation(false);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingLocation(false);
        }
    }

    useEffect(()=>{
        fetchWallOfType();
        fetchLocationDetails();
        fetchWallOfFameRecords([]);
        fetchClients();
    },[])


    const getStatusFromRow = (row) => {
        const isActive = row.isactive ? "Active" : "";
        const isArchived = row.isarchived ? "Archived" : "";
        const isApproved = row.isapproved ? "Approved" : "";
    
        // Concatenate the statuses with a comma
        const status = [isActive, isArchived, isApproved].filter(Boolean).join(", ");
        return status || "Unknown";
    };

    const columns = [
        {
            dataField: 'employeeEmail',
            text: 'Employee Name',
            sort: true,
            formatter: (cell, row) => getNameFromEmail(row.employeeEmail),
        },
        {
            dataField: 'raisedbyEmail',
            text: 'Referred By Email',
            sort: true,
            formatter: (cell, row) => getNameFromEmail(row.raisedbyEmail),
        },
        {
            dataField: 'clientname',
            text: 'Client Name',
            sort: true
        },
        {
            dataField: 'wall_of_fame_type',
            text: 'Wall Of Fame Type',
            sort: true,
            formatter: (cell, row) => row.wall_of_fame_type?.walloffametype,
            sortValue: (cell, row) => row.wall_of_fame_type?.walloffametype
        },
        {
            dataField: 'clientmessage',
            text: 'Description',
            sort: true,
            headerStyle: { width: '30%' }
        },
        {
            dataField: 'clientappreciation',
            text: 'Client Appreciation',
            sort: true
        },
        {
            dataField: 'imageurl',
            text: 'Image URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'publishdate',
            text: 'Publish Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.publishdate);
            },
            sortValue: (cellContent, row) => {
                return formatDate(row?.publishdate);
            }
        },
        {
            dataField: 'expirydate',
            text: 'Expiry Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.expirydate);
            },
            sortValue: (cellContent, row) => {
                return formatDate(row?.expirydate);
            }
        },
        {
            // dataField: 'isactive',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => {
                const status = getStatusFromRow(row);
                return status;
            },
            sortValue: (cellContent, row) => {
                const status = getStatusFromRow(row);
                return status;
            }
        },
        {
            dataField: 'siteaccessmulti',
            text: 'Location',
            sort: true,
            formatter: (cell, row) => {
                if (row.siteaccessmulti && row.siteaccessmulti.length > 0) {
                    return row.siteaccessmulti.map(item => item.value).join(', ');
                } else {
                    return '';
                }
            }
        },
        {
            dataField: 'action',
            text: 'Actions',
            align: 'center',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ];

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetchingRecords(true);
            const request = { resource: `api/walloffame/${row.id}` };
            const response = await api.walloffame.softDeleteRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                 toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message);
        } finally{
            setIsfetchingRecords(false);
        }
    }


    const handleEdit = (row) => {
          // Check if any field is null
          const hasNullField = [
            row.employeeEmail,
            row.raisedbyEmail,
            row.siteaccessmulti,
            row.publishdate,
            row.expirydate,
            row.clientid,
            row.clientappreciation,
            row.clientmessage,
            row.typeid
        ].some(field => field === null || field === '');

    // If any field is null, show error and return from the function
    if (hasNullField) {
        toastr.error('Missing Required Fields!');
        return;
    }
        setIntialValues(row)
        setCurrentId(row.id);
        navigate('/wallofFameForm', {state:{data, types, sites, clients, currentId:row.id, intialData:row, isEdit: true}})
    };

    const handleNew = () =>{
        navigate('/wallofFameForm', {state:{data, types, sites, clients, currentId,isEdit: false}})
    }

    // Debounced the fetchWallOfFameRecords function to prevent multi api calls
    const debouncedFetchWallOfFameRecords = useCallback(
        debounce((selectedCountries) => {
            fetchWallOfFameRecords(selectedCountries);
        }, 2000), // Adjust the delay 
        []
    );

    const handleLocationChange = (selected) => {
        const selectedCountries = selected.map(location => location.value);
        // setSelectedLocations(selected)
        if (selectedCountries.includes('GLOBAL')) {
            const allLocations = sites.map(loc => ({ value: loc.country, label: loc.country }));
            setSelectedLocations([{ value: 'GLOBAL', label: 'GLOBAL' }, ...allLocations]);
            debouncedFetchWallOfFameRecords(['GLOBAL']);
        } else {
            setSelectedLocations(selected);
            debouncedFetchWallOfFameRecords(selectedCountries);
        }
        // fetchWallOfFameRecords(selectedCountries);
    };

    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []);


    return (
        <div className={styles.fameContainer}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
            <h4 className="my-4 managePageTitle">Manage Wall of Fame</h4>
            {!isfetching && <div className={`${styles.filtersWrap} d-flex align-items-center  mt-3 mb-1`}>
                <MultiSelect
                    className={`${styles.multiSelect} me-3`}
                    options={[
                        { value: 'GLOBAL', label: 'GLOBAL' }, // Option for selecting all locations
                        ...sites.map(loc => ({ value: loc.country, label: loc.country })) // Options for individual locations
                    ]}
                    value={selectedLocations}
                    onChange={handleLocationChange}
                    labelledBy='SelectLocation'
                    disableSearch={true}
                    hasSelectAll={false}
                    shouldCloseOnSelect={false}
                    selectAllLabel='GLOBAL'
                    overrideStrings={{
                        selectSomeItems: "Select Location", // Custom placeholder text
                    }}
                    />
                <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                     Add New
                </Button>
            </div>}
            </div>
        {isfetching && <ContainerLoader/>}
        <div className="walloffame_wrap mb-5">
            {data && data?.length > 0 && !isfetching &&(<div className='row'>
                <div className='col-12'>
                    <div className={`${styles.dataTableWrapper} mx-auto`}>
                        <DataTables records={data} columns={columns}/>
                    </div>   
                </div>
            </div>)}

            {data?.length === 0 && !isfetching && <Alert variant='danger' className='mx-auto' style={{width:'90%'}}>No Records Found!!!</Alert>}
          
        </div>
        </div>
    );
}