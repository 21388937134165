import { useEffect, useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { formatDate } from '../../utils/formatDate';
import { MultiSelect } from 'react-multi-select-component';
import { debounce } from '../../utils/debounce';
import { useSelector } from 'react-redux';

const getPolicyTypeName = (typeId) => {
    switch (typeId) {
        case 1:
            return 'HR Policies';
        case 2:
            return 'IT Policies';
        case 3:
            return 'TA Policy';
        case 4:
            return 'Benefits';
        default:
            return 'Unknown Policy Type';
    }
};

export default function ManagePolicy() {
    const [types, setTypes] = useState([]);
    const [data, setData] = useState([]);
    const [sites, setSites] = useState([{value:'1', label: 'Offshore'}, {value:'2', label: 'Onshore'}, {value:'3', label: 'Nearshore'}]);
    const [isfetching, setIsfetching] = useState(false);
    const [isfetchingTypes, setIsfetchingTypes] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [intialValues, setIntialValues] = useState()
    const [selectedLocations, setSelectedLocations] = useState([]);

    const navigate = useNavigate()

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const userCountry = localStorage.getItem('userCountry');
    const {
        setValue
    } = useForm();

    const fetchPolicyType = async () => {
        try {
            setIsfetchingTypes(true);
            const request = { resource: 'api/policytype' };
            const policyTypeData = await api.PolicyTypeService.getPolicytype(request);
            const data = policyTypeData.data.data.res;
            setTypes(data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingTypes(false);
        }
    };

    const fetchPolicyRecords = async (selectedLocations) => {
        try {
            setIsfetching(true);
            const request = { resource: 'api/policies' };
            const policy_Data = await api.policiesservice.getPolicies(request);
            let filteredData = policy_Data.data.data.res;
            if (selectedLocations && selectedLocations.length > 0) {
                if (selectedLocations.includes('GLOBAL')) {
                    setData(filteredData);
                } else {
                    filteredData = policy_Data.data.data.res.filter(record => {
                        return selectedLocations.some(selected => record.siteaccess === selected);
                    });
                    setData(filteredData);
                }

            }
            setData(filteredData)
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetching(false)
        }
    }

    const fetchLocationDetails = async () => {
        try {
            setIsfetching(true)
            const request = { resource: 'api/zoho/getLocation' }
            const response = await api.ZohoService.getLocation(request)
            const filteredSites = response.data.data.filter(loc => loc.country.trim() !== '');
            setSites(filteredSites)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchPolicyRecords([]);
        fetchPolicyType();
    }, []);


    const getStatusFromRow = (row) => {
        const isActive = row.isactive ? "Active" : "";
        const isDeleted = row.isdeleted ? "Deleted" : "";

        // Concatenate the statuses with a comma
        const status = [isActive, isDeleted].filter(Boolean).join(", ");
        return status || "Unknown";
    };

    const columns = [
        {
            dataField: 'policyname',
            text: 'Policy Name',
            sort: true
        },
        {
            dataField: 'policydescription',
            text: 'Policy Description',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'policydocumenturl',
            text: 'Policy Document URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        // {
        //     dataField: 'policytileurl',
        //     text: 'Policy Tile URL',
        //     sort: true,
        //     formatter: (cellContent, row) => {
        //         return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
        //     }
        // },
        {
            dataField: 'policytypeid',
            text: 'Policy Type',
            sort: true,
            formatter: (cellContent, row) => {
                return getPolicyTypeName(row.policytypeid);
            }
        },
        {
            dataField: 'publishdate',
            text: 'Publish Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.publishdate);
            }
        },
        {
            dataField: 'expirydate',
            text: 'Expiry Date',
            sort: true,
            formatter: (cellContent, row) => {
                return formatDate(row?.expirydate);
            }
        },
        {
            // dataField: 'isactive',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => {
                const status = getStatusFromRow(row);
                return status;
            }

        },
        {
            dataField: 'siteaccess',
            text: 'Location',
            sort: true,
            formatter: (cell, row) => {
                if (row?.siteaccess === '1') return 'Offshore';
                if (row?.siteaccess === '2') return 'Onshore';
                return 'Nearshore';
            }
        },
        {
            dataField: 'action',
            text: 'Actions',
            align: 'center',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ];

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetching(true)
            const request = { resource: `api/policies/${row.id}` };
            const response = await api.policiesservice.softDeleteRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetching(false);
        }
    }

    const handleEdit = (row) => {
        const hasNullField = [
            row.policyname,
            row.policydescription,
            row.policydocumenturl,
            // row.policytileurl,
            row.policytypeid,
            row.publishdate,
            row.expirydate,
            row.Status,
            row.siteacces,
        ].some(field => field === null || field === '');

        // If any field is null, show error and return from the function
        if (hasNullField) {
            toastr.error('Missing Required Fields!');
            return;
        }
        setIntialValues(row)
        setCurrentId(row.id);
        navigate('/AddPolicyForm', { state: { data, types, sites, currentId: row.id, intialData: row, isEdit: true } })
    };

    const handleNew = () => {
        navigate('/AddPolicyForm', { state: { data, types, sites, currentId, isEdit: false } })
    }

    const debouncedFetchPolicyRecords = useCallback(
        debounce((selectedCountries) => {
            fetchPolicyRecords(selectedCountries);
        }, 2000), // Adjust the delay 
        []
    );

    const handleLocationChange = (selected) => {
        const selectedCountries = selected.map(location => location.value);
        if (selectedCountries.includes('GLOBAL')) {
            const allLocations = sites.map(loc => ({ value: loc.value, label: loc.label }));
            setSelectedLocations([{ value: 'GLOBAL', label: 'GLOBAL' }, ...allLocations]);
            debouncedFetchPolicyRecords(['GLOBAL']);
        } else {
            setSelectedLocations(selected);
            debouncedFetchPolicyRecords(selectedCountries);
        }
    };

    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []);

    return (
        <div className={styles.fameContainer}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
                <h4 className="my-4 managePageTitle">Manage Policies and Benefits</h4>
                {!isfetching && <div className={`${styles.filtersWrap} d-flex align-items-center  mt-3 mb-1`}>
                    <MultiSelect
                        className={`${styles.multiSelect} me-3`}
                        options={[
                            { value: 'GLOBAL', label: 'GLOBAL' }, // Option for selecting all locations
                            ...sites.map(loc => ({ value: loc.value, label: loc.label })) // Options for individual locations
                        ]}
                        value={selectedLocations}
                        onChange={handleLocationChange}
                        labelledBy='Select Location'
                        disableSearch={true}
                        hasSelectAll={false}
                        shouldCloseOnSelect={false}
                        selectAllLabel='GLOBAL'
                    />
                    <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                        Add New
                    </Button>
                </div>}
            </div>
            {(isfetching || isfetchingTypes) && <ContainerLoader />}
            <div className="walloffame_wrap mb-5">
                {data && data?.length > 0 && !isfetching && (<div className='row'>
                    <div className='col-12'>
                        <div className={`${styles.dataTableWrapper} mx-auto`}>
                            <DataTables records={data} columns={columns} />
                        </div>
                    </div>
                </div>)}

                {data?.length === 0 && !isfetching && <Alert variant='danger' className='mx-auto' style={{ width: '90%' }}>No Records Found!!!</Alert>}

            </div>
        </div>
    );
}
