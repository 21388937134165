import React from 'react'
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

const ClientDetails = () => {

    const [data, setData] = useState([])
    const [industry, setIndusrty] = useState([]);
    const [isfetchingClients, setIsfetchingClients] = useState(false);
    const [isfetchingIndusrty, setIsfetchingIndusrty] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const navigate = useNavigate()
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const fetchClientRecords = async () => {
        try {
            setIsfetchingClients(true);
            const request = { resource: 'api/clientsinformation' };
            const response = await api.clientsinformation.getDetails(request)
            const filteredData = response.data.data.res.filter(item => (item.isdeleted === false))
            setData(filteredData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingClients(false);
        }
    }

    const fetchIndustryDetails = async () => {
        try {
            setIsfetchingIndusrty(true);
            const request = { resource: 'api/industry' };
            const response = await api.clientsinformation.getIndustryDetails(request);
            setIndusrty(response.data.data.res);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetchingIndusrty(false);
        }
    }

    const getTitleById = (id) => {
        const industryName = industry?.find(item => item?.id === id);
        return industryName ? industryName?.industry_title : 'Unknown';
    };

    const enrichedData = data.map(item => ({
        ...item,
        industry_title: industry.find(ind => ind.id === item.industry_type_id)?.industry_title || 'Unknown'
    }));

    const getStatusFromRow = (row) => {
        const isActive = row.isactive ? "Active" : "Inactive";

        // Concatenate the statuses with a comma
        const status = [isActive].filter(Boolean).join(", ");
        return status || "Unknown";
    };


    const columns = [
        {
            dataField: 'industry_type_id',
            text: 'Industry',
            sort: true,
            formatter: (cellContent, row) => {
                return getTitleById(row.industry_type_id);
            }
        },
        {
            dataField: 'client_name',
            text: 'Client Name',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'redirect_url',
            text: 'Redirect URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'image_url',
            text: 'Image URL',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'isactive',
            text: 'Status',
            sort: true,
            formatter: (cellContent, row) => {
                const status = getStatusFromRow(row);
                return status;
            }

        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ];

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetchingClients(true)
            const request = { resource: `api/clientsinformation/${row.client_id}` };
            const response = await api.clientsinformation.softDeleteRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetchingClients(false);
        }
    }

    const handleEdit = (row) => {
        const hasNullField = [
            row.client_name,
            row.description,
            row.redirect_url,
            row.image_url,
        ].some(field => field === null || field === '');

        // If any field is null, show error and return from the function
        if (hasNullField) {
            toastr.error('Missing Required Fields!');
            return;
        }
        setCurrentId(row.client_id);
        navigate('/clientdetailsForm', { state: { data, industry, currentId: row.client_id, intialData: row, isEdit: true } })
    };

    const handleNew = () => {
        navigate('/clientdetailsForm', { state: { data, industry, currentId, isEdit: false } })
    }

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])

    useEffect(() => {
        fetchClientRecords();
        fetchIndustryDetails();
    }, [])

    return (
        <div className={styles.fameContainer} style={{ minHeight: '1150px', height: 'auto', overflow: 'auto' }}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
                <h4 className="my-4 managePageTitle">Manage Client Details</h4>
                {(!isfetchingClients && !isfetchingIndusrty) && <div className={`${styles.filtersWrap} d-flex align-items-center  justify-content-end mt-3 mb-1`}>
                    <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                        Add New
                    </Button>
                </div>}
            </div>
            {(isfetchingClients || isfetchingIndusrty) && <ContainerLoader />}
            <div className="walloffame_wrap mb-5">
                {data && data?.length > 0 && (!isfetchingClients && !isfetchingIndusrty) && (<div className='row'>
                    <div className='col-12'>
                        <div className={`${styles.dataTableWrapper} mx-auto`}>
                            <DataTables records={enrichedData} columns={columns} />
                        </div>
                    </div>
                </div>)}

                {data?.length === 0 && (!isfetchingClients && !isfetchingIndusrty) && <Alert variant='danger' className='mx-auto' style={{ width: '90%' }}>No Records Found!!!</Alert>}

            </div>
        </div>
    )
}

export default ClientDetails