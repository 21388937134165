import React from 'react';
import './LearningLoungeAdmin.scss'
import { useNavigate } from 'react-router-dom';
import { TfiLayoutSlider } from "react-icons/tfi";
import { MdOutlineEvent, MdOutlineFeedback, MdOutlineTextRotationNone } from "react-icons/md";
import { IoCalendarOutline, IoCalendarSharp, IoExtensionPuzzleOutline, IoVideocamOutline } from "react-icons/io5";
import { IoIosMicrophone } from "react-icons/io";
import { SiUdemy } from "react-icons/si";
import { FaPuzzlePiece } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";
import { TbCaptureFilled } from "react-icons/tb";
import { GiBookshelf } from "react-icons/gi";
import { MdOutlineReviews } from "react-icons/md";
import { FaUserGraduate } from "react-icons/fa";
import { GiTeacher, GiStarsStack } from "react-icons/gi";
import { SlGraduation } from "react-icons/sl";

function ManageLearningLounge() {
    const navigate = useNavigate();

    return (
        <div style={{ minHeight: '1150px', height: 'auto', overflow: 'auto', paddingTop: '5rem' }}>
            <div>
                <h4 className="my-4 managePageTitle">Manage Learning Lounge</h4>
            </div>
            <div className='d-flex flex-wrap'>
                {/* navigate('/flashNewsForm', {state:{data, sites, currentId:row.id, intialData:row, isEdit: true}}) */}
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:1, menu:'Scrolling Text'}})}>
                    <MdOutlineTextRotationNone size={30} /><div>Manage Scrolling Text</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:2, menu:'Programs Availabale'}})}>
                    <MdOutlineEvent size={30} /><div>Manage Programs Availabale</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:3, menu:'Moments Captured'}})}>
                    <TbCaptureFilled size={30} /><div>Manage Moments Captured</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:4, menu:'Learning Calendar'}})}>
                    <IoCalendarOutline size={30} /><div>Manage Learning Calendar</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:5, menu:'Udemy Request'}})}>
                    <SiUdemy size={30} /><div>Manage Udemy Request</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:6, menu:'Intellect Junction'}})}>
                    <GiBookshelf size={30} /><div>Manage Intellect Junction</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:7, menu:'Book Review'}})}>
                    <MdOutlineReviews size={30} /><div>Manage Book Review</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:8, menu:'All Things Considered'}})}>
                    <IoIosMicrophone size={30} /><div>Manage All Things Considered</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:9, menu:'F5 Corner'}})}>
                    <IoExtensionPuzzleOutline size={30} /><div>Manage F5 Corner</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:10, menu:'Knowledge Nuggets'}})}>
                    <SlGraduation size={30} /><div>Manage Knowledge Nuggets</div>
                </div>
                <div className='learningCard' onClick={() => navigate('/learningloungetable', {state:{ menuTypeId:11, menu:'Videos'}})}>
                    <IoVideocamOutline size={30} /><div>Manage Videos</div>
                </div>
            </div>
        </div>
    )
}

export default ManageLearningLounge
