import React from "react";

const selectStyle = {
    border: "1px solid #00000024",
    borderRadius: "2px",
    height: "3em",
    padding: "5px",
    fontSize: "0.9em",
    outline: "none",
};

const Selection = ({ options, selected="", onChange }) => {
    return (
        <select 
            style={selectStyle} 
            value={selected}
            onChange={(e) => onChange(e.target.value)}>
            <option value="">Select an option</option>
            {options.map((option, key) => (
                <option value={option} key={key}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export default Selection;
