import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from 'react-icons/io';
import { questioOptionTypes } from '../swipeSlider/SurveyHome';
import ContainerLoader from '../loader/container-loader';
import api from '../../api';
import { toastr } from 'react-redux-toastr';

const AddQuestion = ({ handleClose, updateQuestions, isEdit = false, editFormData = {}, currentpollid }) => {

    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm();

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;
    const navigate = useNavigate();

    const initial_poll_single_option = [{ optiontype: null, pollquestionid: null, iscommentsrequired: false }];

    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true);

    const [pollId, setPollId] = useState(editFormData?.pollid)
    const [questionId, setQuestionId] = useState(currentpollid)
    const [question, setQuestion] = useState(editFormData?.question)
    const [questiontype, setQuestiontype] = useState(editFormData?.questiontype);
    const [pollQuestionOptions, setPollQuestionOptions] = useState(editFormData?.poll_question_options || initial_poll_single_option);

    // console.log('INTIAL =  ', 'pollId', pollId, 'questionId', questionId, 'question', question, 'questiontype', questiontype, 'pollQuestionOptions', pollQuestionOptions)

    const [selectedType, setSelectedType] = useState(editFormData?.questiontype || null);

    const [poll_question_options, setpoll_question_options] = useState(editFormData?.poll_question_options || initial_poll_single_option);

    const [initialOptionValues, setinitialOptionValues] = useState([{ optiontype: Number(selectedType), pollquestionid: editFormData?.pollid, iscommentsrequired: false }, { optiontype: Number(selectedType), pollquestionid: editFormData?.pollid, iscommentsrequired: false }]);

    // const [options, setOptionValues] = useState(initialOptionValues);


    const handleSelectChange = (e) => {
        // console.log('questionId', questionId, currentpollid);
        // const defaultOptionValues = [...initialOptionValues];
        // setOptionValues(defaultOptionValues);
        setSelectedType(Number(e.target.value));
        setQuestiontype(Number(e.target.value));
        // console.log('e.target.value', e.target.value)
        if (Number(e.target.value) === 5 || Number(e.target.value) === 6 || Number(e.target.value) === 7 || Number(e.target.value) === 8) {
            setPollQuestionOptions([{ optiontype: Number(e.target.value), pollquestionid: questionId, iscommentsrequired: false }, { optiontype: Number(e.target.value), pollquestionid: questionId, iscommentsrequired: false }])
        } else {
            setPollQuestionOptions([{ optiontype: Number(e.target.value), pollquestionid: questionId, iscommentsrequired: false }])
        }
    };

    const updatedFormdata = (formData) => {
        if (Number(selectedType) === 7) {
            return { ...formData, pollid: currentpollid, displayorder: Number(formData.displayorder), ismandatory: true, ismultiSelect: true, questiontype: Number(formData.type), poll_questions: pollQuestionOptions };
        }
        else {
            return { ...formData, pollid: currentpollid, displayorder: Number(formData.displayorder), ismandatory: true, ismultiSelect: false, questiontype: Number(formData.type), poll_questions: pollQuestionOptions };
        }
    }

    const handleData = async (formData) => {
        const updatedData = updatedFormdata(formData);
        console.log('collected form data', updatedData);

        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/pollquestions/${questionId}` };
                const editResponse = await api.poll.updatePollQuestions(request, {
                    ...updatedData,
                })
                if (editResponse.data.status === 200) {
                    toastr.success('Success', ' Question Updated Successfully!');
                    const questOptionsRequest = { resource: 'api/pollquestionsoptions' };
                    const optionsRes = await api.poll.updateQuestionsOptions(questOptionsRequest, {
                        pollquestionsoptions: updatedData.poll_questions
                    });
                    if (optionsRes.data.status === 200) {
                        toastr.success('Success', ' Question Options Updated Successfully!');
                        setIsfetching(false);
                        navigate('/surveyQuestionsForm')
                    } else {
                        toastr.error('Error', optionsRes.data.message);
                    }
                } else {
                    toastr.error('Error', editResponse.data.message);
                }
            } catch (error) {
                console.log(error.message)
            } finally {
                handleClose();
                setIsfetching(false);
            }
        } else {
            try {
                const request = { resource: 'api/pollquestions' };
                setIsfetching(true);
                setShowForm(false);
                //add quest first
                const res = await api.poll.addPollQuestions(request, {
                    ...updatedData,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Question added Successfully!');
                    const questOptionsRequest = { resource: 'api/pollquestionsoptions' };
                    const optionsRes = await api.poll.addPollQuestionsOptions(questOptionsRequest, {
                        pollquestionsoptions: updatedData.poll_questions
                    });

                    if (optionsRes.data.status === 200) {
                        toastr.success('Success', ' Question Options added Successfully!');
                        setIsfetching(false);
                        navigate('/surveyQuestionsForm')
                    } else {
                        toastr.error('Error', optionsRes.data.message);
                    }
                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            } finally {
                handleClose();
                setIsfetching(false);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)
    };

    // useEffect(() => {
    // }, [initialOptionValues])


    // useEffect(() => {
    //     // setinitialOptionValues([{ optiontype: Number(selectedType), pollquestionid: editFormData?.pollid, iscommentsrequired: false }, { optiontype: Number(selectedType), pollquestionid: editFormData?.pollid, iscommentsrequired: false }]);
    // }, [selectedType])


    const addOption = () => {
        // setOptionValues([...options, { optiontype: Number(selectedType), pollquestionid: editFormData?.pollid, iscommentsrequired: false }]);
        setPollQuestionOptions([...pollQuestionOptions, { optiontype: Number(selectedType), pollquestionid: questionId, iscommentsrequired: false }]);
    };

    const deleteOption = (index) => {
        const updatedOptionValues = pollQuestionOptions.filter((_, i) => i !== index);
        setPollQuestionOptions(updatedOptionValues);
        // setOptionValues(updatedOptionValues);
    };

    // useEffect(() => {
    //     console.log('selectedType poll_question_options', selectedType, poll_question_options)
    // }, [])

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [role_id, navigate]);

    useEffect(() => {
        if (isEdit && editFormData) {
            Object.keys(editFormData).forEach((key) => {
                if (key === 'questiontype') {
                    const matchedTypeID = questioOptionTypes.filter((item) => item.key == editFormData[key])[0].key;
                    setValue('type', questioOptionTypes.filter((item) => item.key == editFormData[key])[0].key);
                    setSelectedType(matchedTypeID);
                } else if ((key === 'poll_question_options')) {
                    // setOptionValues(editFormData[key]);
                    setPollQuestionOptions(editFormData[key]);
                } else {
                    setValue(key, editFormData[key]);
                }
            });
        }
    }, [editFormData, setValue]);

    return (<>
        {isfetching && <ContainerLoader />}
        {showForm && <div className={` mx-auto p-2`} style={{ paddingTop: '10px !important' }}>
            <h4 className="my-4 pagetitle">Question Form</h4>
            <div className='row' style={{ overflowY: 'scroll', maxHeight: '450px' }}>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-12 p-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Question"
                                        name="question"
                                        {...register('question', { required: 'Question is required' })}
                                        onChange={(e) => { setQuestion(e.target.value) }}
                                    />
                                    {errors.question && <p style={{ color: 'red' }}>{errors.question.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Option Type</Form.Label>
                                    <Form.Select
                                        name="type"
                                        {...register('type', { required: 'Option Type is required' })}
                                        className="mb-3"
                                        aria-label="Default select example"
                                        onChange={(e) => { handleSelectChange(e) }}
                                    >
                                        <option value="">Select Option Type</option>
                                        {questioOptionTypes?.map((type, index) => (
                                            <option key={index} value={type.key}>
                                                {type.value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.type && <p style={{ color: 'red' }}>{errors.type.message}</p>}
                                </Form.Group>

                                {/* {(selectedType.toLowerCase() === 'radio' || selectedType.toLowerCase() === 'select' || selectedType.toLowerCase() === "multiselect" || selectedType.toLowerCase() === "checkbox") && ( */}
                                {(Number(selectedType) === 5 || Number(selectedType) === 6 || Number(selectedType) === 7 || Number(selectedType) === 8) && (
                                    <div>
                                        <Form.Label>Enter Options</Form.Label>
                                        {pollQuestionOptions.map((item, index) => (
                                            <Row key={index} className="mb-2">
                                                <Col md={10}>
                                                    <Controller
                                                        name={`quesOption${index + 1}`}
                                                        control={control}
                                                        defaultValue={item}
                                                        render={({ field }) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                value={item.options}
                                                                onChange={(e) => {
                                                                    const updatedValues = pollQuestionOptions;
                                                                    updatedValues[index].options = e?.target?.value;
                                                                    updatedValues[index].pollquestionid = editFormData?.pollid;
                                                                    // setOptionValues(updatedValues);
                                                                    setPollQuestionOptions(updatedValues);
                                                                    field.onChange(e);
                                                                }}
                                                                onBlur={(e) => field.onChange(e)}
                                                                placeholder={`Option ${index + 1}`}
                                                                autoFocus
                                                            />
                                                        )}
                                                        rules={{ required: `Option ${index + 1} is required` }}
                                                    />
                                                    {errors[`quesOption${index + 1}`] && <p style={{ color: 'red' }}>{errors[`quesOption${index + 1}`].message}</p>}

                                                    {errors.type && <p style={{ color: 'red' }}>{errors.type.message}</p>}
                                                </Col>

                                                <Col md={1}>
                                                    {index > 1 && <Button
                                                        variant="danger"
                                                        onClick={() => deleteOption(index)}
                                                    >
                                                        <MdDelete />
                                                    </Button>}
                                                </Col>
                                                <Col md={1}>
                                                    {pollQuestionOptions.length === index + 1 &&
                                                        <Button variant="secondary" onClick={() => addOption()}>
                                                            <IoMdAdd size={20} />
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>

                                )}
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Display Order</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Display Order"
                                        name="displayorder"
                                        {...register('displayorder', { required: 'Display Order is required' })}
                                        onChange={(e) => { setQuestion(e.target.value) }}
                                    />
                                    {errors.displayorder && <p style={{ color: 'red' }}>{errors.displayorder.message}</p>}
                                </Form.Group>
                            </div>

                            <div className='d-flex justify-content-end col-12 p-2'>
                                {/* <Button variant="secondary" className='me-2' onClick={() => { handleClose(); setSelectedType(''); setOptionValues(initialOptionValues) }}> */}
                                <Button variant="secondary" className='me-2' onClick={() => { handleClose(); setSelectedType(''); setPollQuestionOptions(initialOptionValues) }}>
                                    Cancel
                                </Button>
                                {/* <Button type="reset" variant="secondary" className='mx-2' onClick={() => { reset(); setSelectedType(''); setOptionValues(initialOptionValues) }}> */}
                                <Button type="reset" variant="secondary" className='mx-2' onClick={() => { reset(); setSelectedType(''); setPollQuestionOptions(initialOptionValues) }}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className='ms-2'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>}
    </>
    )
}

export default AddQuestion;
