import {
    CrudService,
    UserService,
    AuthService,
    AzureService,
    AzureGraphService,
} from './services';
import { WallofFameService } from './services/walloffame';
import {DiscoverPortalService} from './services/discoverPortal';
import { RoleService } from './services/role';
import { WhatsNewService } from './services/whatsnew';
import { EntertainmentDailyService } from './services/entertainmentdaily';
import { WallofFameTypeService } from './services/walloftype';
import { PoliciesService } from './services/policies';
import { PolicyTypeService } from './services/policytype';
import { PoliciesSiteAccessService } from './services/policiessiteaccess';
import { ZohoService } from './services/zoho';
import { WhatsNewSiteAccessService } from './services/whatsnewsiteaccess';
import { AssociateDetailsService } from './services/associatedetails';
import { ItHubService } from './services/itHub';
import { FlashNewsService } from './services/flashNews';
import { InsightsService } from './services/insights';
import { LearningLoungeService } from './services/learningLounge';
import { ClientsInformationService } from './services/clientsinformation';
import { DepartmentService } from './services/departments';
import { PollService } from './services/poll';

export default class EdsApiClient {
    crud;
    user;
    auth;
    azure;
    azureGraphApi;
    store = {
        apiDomain: '',
        token: undefined,
    };

    constructor(apiDomain) {
        this.store.apiDomain = apiDomain;
        this.crud = new CrudService(this.store);
        this.user = new UserService(this.store);
        this.auth = new AuthService(this.store);
        this.rolelist = new RoleService(this.store);
        this.azure = new AzureService(this.store);
        this.azureGraphApi = new AzureGraphService();
        this.walloffame = new WallofFameService(this.store);
        this.discoverportal = new DiscoverPortalService(this.store);
        this.whatsnew = new WhatsNewService(this.store);
        this.insights = new InsightsService(this.store);
        this.entertainmentdaily = new EntertainmentDailyService(this.store);
        
        this.policiesservice = new PoliciesService(this.store);
        this.PolicyTypeService = new PolicyTypeService(this.store);
        this.policiessiteaccessservice = new PoliciesSiteAccessService(this.store); 
        this.WallofFameTypeService = new WallofFameTypeService(this.store);
        this.ZohoService = new ZohoService(this.store);
        this.WhatsNewSiteAccessService = new WhatsNewSiteAccessService(this.store);
        this.associatedetails = new AssociateDetailsService(this.store);
        this.itHub = new ItHubService(this.store);
        this.flashNews = new FlashNewsService(this.store);
        this.learningLounge = new LearningLoungeService(this.store);
        this.clientsinformation = new ClientsInformationService(this.store);
        this.departments = new DepartmentService(this.store);
        this.poll = new PollService(this.store);
    }

    setToken(token) {
        this.store.token = token;
    }
}
