import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelection = ({ options = [], selected = [], setSelected = () => { } }) => {
    const formatedOptions = []
    options.map((option) => {
        formatedOptions.push({ value: option, label: option })
    }

    )

    return (
        <div>
            <MultiSelect
                options={formatedOptions}
                value={selected}
                onChange={setSelected}
            />
        </div>
    );
};

export default MultiSelection

