import React from 'react';
import './main.css';

function Main({ children }) {
    return (
        <>
            {children}
        </>
    );
}

export default Main;
