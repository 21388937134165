import React from 'react'
import { Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { MdClose } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function GallerySlider({ handleClose, images, initialSlide }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlide
  };
  
  return (
    <div className="slider-container gallerySlider">
      <div className="d-flex justify-content-end">
        <Button className="btn btn-sm btn-primary float-right" onClick={() => { handleClose() }}><MdClose /></Button>
      </div>
      <Slider {...settings}>
        {images.map((item, index) => <div key={index}>
          <img src={item.media_url} style={{ height: '60vh', margin: 'auto' }} />
        </div>)}
      </Slider>
    </div>
  );
}

export default GallerySlider
