import React from "react";
import Input from "./compontnts/Input";
import Radio from "./compontnts/Radio";
import Checkbox from "./compontnts/Checkbox";
import Textarea from "./compontnts/Textarea";
import Selection from "./compontnts/Selection";
import MultiSelection from "./compontnts/MultiSelection";

const Question = ({ question, index, onAnswerChange, selected, answers }) => {

    const handleInputChange = (value) => {
         onAnswerChange(question.id, value);
    };
    
    const showAnswerComponent = (question, answers) => {
        switch (question.type.toLowerCase()) {
            case "text":
                return <Input type={question.type} onChange={(e) => handleInputChange(e.target.value)} />;
            case "number":
                return <Input type={question.type} onChange={(e) => handleInputChange(e.target.value)} />;
            case "date":
                return <Input type={question.type} onChange={(e) => handleInputChange(e.target.value)} />;
            case "radio":
                return <Radio options={question.options} alignAnswers={question?.alignAnswers} onChange={(e) => handleInputChange(e?.target?.value)} />;
            case "select":
                return <Selection options={question.options} selected={selected || ""} onChange={handleInputChange}  />;
            case "textarea":
                return <Textarea onChange={(e) => handleInputChange(e.target.value)} />;
            case "checkbox":
                // return <Checkbox options={question.options} onChange={(selectedOptions) => handleInputChange(selectedOptions)} />;
                return <Checkbox
                        options={question.options}
                        selected={selected || []}
                        onChange={handleInputChange}
                    />
            case "multiselect":
                return <MultiSelection options={question.options} selected={selected || []} setSelected={handleInputChange} label="Select" />;
            default:
                return null;
        }
    };

    const questionStyle = {
        marginBottom: '2rem',
        display: 'flex',
        flexDirection: 'column'
    };

    return (
        <div style={questionStyle}>
            <div>
                {index + 1}. {question.title}
            </div>
            <div>
                {showAnswerComponent(question, answers)}
            </div>
        </div>
    );
};

export default Question;

