import React from "react";

const textareaStyle = {
  resize: "none",
  lineHeight: "25px",
  color: "#000000",
};

const Textarea = ({onChange=()=>{}, disabled=false}) => {
  return <textarea rows="5" cols="50" style={textareaStyle} onChange={onChange} disabled={disabled} />;
};

export default Textarea;
