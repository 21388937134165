import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAuthProvider } from '../../containers/auth/azureAuthProvider';
import axios from 'axios';
import { StringEncryption } from '../../utils';
import feedback from "../../assets/logo/learningLounge/feedback-transparent.png";
import './LearningLounge.scss'

function FeedbackUs() {
    const [showForm, setShowForm] = useState(false);
    const [feedbackSubject, setFeedbackSubject] = useState('');
    const [feedbackDesc, setFeedbackDesc] = useState('');
    const feedbackRef = useRef(null);
    const feedbackBtnRef = useRef(null);
    const authState = useSelector((state) => state.authUser);

    const sendMail = async ({ feedbackSubject, feedbackDesc }) => {
        const accessToken = await (
            await getAuthProvider().getAccessToken()
        ).accessToken;
        const encryptToken = StringEncryption(accessToken);
        const data = {
            from: `${authState?.profile?.email}`,
            to: 'HRDevelopment@agilisium.com',
            subject: feedbackSubject,
            desc: feedbackDesc,
            accesToken: encryptToken,
        };
        const url = `${process.env.REACT_APP_API_DOMAIN}/api/helpcenter/sent-email`;
        const response = await axios.post(url, data);
        if (response) {
            setFeedbackSubject('');
            setFeedbackDesc('');
            setShowForm(false);
        }
    };

    return (
        <div className='feedbackContainer' onClick={() => setShowForm(true)}>
            <div className='sticky-div' title='Feedback'>
                <img src={feedback} alt='Feedback' />
            </div>
            {showForm && (
                <div id="feedbackContent" className="feedback-content" ref={feedbackRef}>
                    <div className="d-flex flex-column">
                        <p className='my-1'>
                            Write your feedbacks to our expertise.
                        </p>

                        <input
                            className='my-1'
                            type='text'
                            value={feedbackSubject}
                            placeholder='Subject'
                            onChange={(e) =>
                                setFeedbackSubject(e.target.value)
                            }
                        />
                        <textarea
                            className='mailDescTextarea'
                            rows="5"
                            cols="50"
                            maxLength="200"
                            value={feedbackDesc}
                            placeholder='Description'
                            onChange={(e) =>
                                setFeedbackDesc(e.target.value)
                            }
                        ></textarea>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="btn btn-secondary"
                                className="my-2 me-1"
                                style={{ width: '100px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowForm(false);
                                    setFeedbackSubject('');
                                    setFeedbackDesc('');
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="my-2 ms-1"
                                style={{ width: '100px' }}
                                disabled={feedbackSubject === '' || feedbackDesc === ''}
                                onClick={() => sendMail({ feedbackSubject, feedbackDesc })}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FeedbackUs
