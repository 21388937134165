import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm, Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { formatDate } from '../../utils/formatDate';
import { useSelector } from 'react-redux';
import { GrEdit } from "react-icons/gr";
import { MdDelete, MdTableRows } from "react-icons/md";
import DataTables from '../DataTables/DataTable';
import { Alert, Modal } from 'react-bootstrap';
import AddQuestion from './AddQuestion';
import Radio from './compontnts/Radio';
import Checkbox from './compontnts/Checkbox';
import Selection from './compontnts/Selection';
import MultiSelection from './compontnts/MultiSelection';
import Input from './compontnts/Input';
import Textarea from './compontnts/Textarea';
import { questioOptionTypes } from '../swipeSlider/SurveyHome';

const SurveyQuestionsForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const initialEditFormData = {}
    const [editFormData, setEditFormData] = useState(initialEditFormData);
    
    const location = useLocation();
    const navigate = useNavigate();
    
    const intialData = location.state?.intialData || {};
    console.log('SUR Ques Form intialData', intialData)
    const currentpollid = location.state?.intialData?.id || {};
    // const currentpollid = location.state?.currentpollid || {};
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;
    
    // const [questions, setQuestions] = useState(intialData.poll_questions.sort((a, b) => a.displayorder - b.displayorder))
    const [questions, setQuestions] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {

                setValue(key, intialData[key]);
            });
        }
    }, [intialData, setValue]);

    const fetchQuestionsRecords = async (pollId) => {
        try {
            setIsfetching(true);
            // const request = {resource: `api/pollquestions/${pollId}`};
            // const response = await api.poll.getPollQuestionsByPollID(request);
            // let data =  response.data.data.res;
            // setQuestions(data.sort((a, b) => a.displayorder - b.displayorder));
            // console.log('getPollQuestionsByPollID', data)
            console.log('object', intialData.poll_questions.sort((a, b) => a.displayorder - b.displayorder))
            setQuestions(intialData.poll_questions.sort((a, b) => a.displayorder - b.displayorder));
        } catch (error) {
            console.log(error.message)
        } finally{
            setIsfetching(false)
        }
    }
    
    useEffect(()=>{
        fetchQuestionsRecords(currentpollid);
    },[])
    

    const handleData = async (formData) => {

        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/pollquestions/${currentpollid}` };
                const editResponse = await api.poll.updatePollQuestions(request, {
                    ...formData,
                    isactive: formData.status_question === '1' ? true : false,
                    isinactive: formData.status_question === '2' ? true : false,
                })

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/managesurvey')
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/survey' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.survey.addDetails(request, {
                    ...formData,
                    isactive: formData.status_question === '1' ? true : false,
                    isinactive: formData.status_question === '2' ? true : false,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/managesurvey')

                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)

    };


    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])

    const showAnswer = (question) => {
        switch (question.type.toLowerCase()) {
            case "text":
            case "number":
            case "date":
                // return <Input type={question.type} disabled={true} />;
                return '';
            case "radio":
                return <div>{question.options.join(', ')}</div>
            // return <Radio options={question.options} name={question.name} alignAnswers={question?.alignAnswers} />;
            case "select":
                return <div>{question.options.join(', ')}</div>
            // return <Selection options={question.options} />;
            case "textarea":
                // return <Textarea disabled={true} />;
                return '';
            case "checkbox":
                return <div>{question.options.join(', ')}</div>
            // return <Checkbox options={question.options} />
            case "multiselect":
                return <div>{question.options.join(', ')}</div>
            // return <div>{question.options.join(', ')}</div>
            // return <div>{question.options.map((option) => { return option.label }).join(', ')}</div>
            // return <MultiSelection options={question.options} label="Select" />;
            default:
                return null;
        }
    };

    const findOptionType = (typeid) => {
        return questioOptionTypes.filter(item => item.key == typeid)[0]?.value || 'Unknown'
    }

    const columns = [
        {
            dataField: 'question',
            text: 'Question',
            sort: false,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }
        },
        {
            dataField: 'questiontype',
            text: 'Question Type',
            sort: false,
            formatter: (cellContent, row) => {
                return findOptionType(cellContent);
            }
        },
        {
            dataField: 'poll_question_options',
            text: 'Question Options',
            sort: false,
            formatter: (cellContent, row) => {
                return cellContent.map(item => item.options).join(', ');

            }
        },
        {
            dataField: 'displayorder',
            text: 'displayorder',
            sort: false,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        },
    ];

    const handleEdit = (row) => {
        setEditFormData(row);
        setIsEdit(true);
        setShowForm(true);
    }
        // setQuestions(questions.filter((item) => item.id !== row.id));// call delete API
    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsfetching(true)
            const request = { resource: `api/pollquestions/${row.id}` };
            const response = await api.poll.deletePollQuestionRecord(request)
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsfetching(false);
        }
    }

    const handleClose = () => {
        setEditFormData(initialEditFormData);
        setIsEdit(false);
        setShowForm(false);

    }


    useEffect(() => {
        //   fetchdata(currentpollid);
        //   setFormData(resp)
    }, [])

    const updateQuestions = (newQues) => {
        console.log('updateQuestions', [...questions, newQues])
        // setQuestions([...questions, newQues])
        return [...questions, newQues];
    }

    return (<div>
        <div style={{ paddingTop: '6rem', minHeight:'1100px', overflow: 'auto' }}>
            <h4 className="my-4 pagetitle">Survey Questions Form</h4>
            <Modal
                className='momentsModal'
                show={showForm}
                size='xl'
                centered
                // onHide={() => handleClose()}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <AddQuestion handleClose={handleClose} updateQuestions={updateQuestions} isEdit={isEdit} editFormData={editFormData} currentpollid={currentpollid} />
                </Modal.Body>
            </Modal>
            <div className='d-flex justify-content-center m-5 p-3 border border-2 rounded-4'>
                <div className='col-12 col-md-12 p-2'>
                    <div><b>{intialData.name}</b></div>
                    <div>{intialData.description}</div>
                </div>
            </div>
        {<div className='row'>
            <div className='p-2 d-flex justify-content-end pe-5'>
                <Button variant="secondary" className='me-2' onClick={() => navigate('/managesurvey')}>
                    Go Back
                </Button>
                <Button className={styles.addRecords} onClick={() => { setEditFormData({pollid:intialData.id}); setShowForm(true); setIsEdit(false) }} variant="primary">
                    Add Question
                </Button>
            </div>
            <div className={`mx-auto px-5`}>
                {<DataTables records={questions} columns={columns} pageSize={5} />}
                {questions?.length === 0 && <Alert variant='danger' className='mx-auto px-5' style={{width:'100%'}}>No Questions Found!!!</Alert>}

            </div>
            <div className='col-12  m-3 p-3'></div>
        </div>}
    </div>
    </div>
)
}

export default SurveyQuestionsForm;
