import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm, Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { formatDate } from '../../utils/formatDate';
import { useSelector } from 'react-redux';

const LearningLoungeForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const menu = location.state?.menu;
    const menuTypeId = location.state?.menuTypeId;
    const intialData = location.state?.intialData || {};
    const data = location.state?.data || {};
    const currentId = location.state?.currentId || {};
    const sites = location.state?.sites || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'publishdate') {
                    const formattedPublishDate = formatDate(intialData.publishdate);
                    setValue('publishdate', formattedPublishDate);
                } else if (key === 'expirydate') {
                    const formattedExpiryDate = formatDate(intialData.expirydate);
                    setValue('expirydate', formattedExpiryDate);
                } else if (key === 'isactive' || key === 'isinactive') {
                    setValue('status_learning', intialData.isactive ? '1' : intialData.isinactive ? '2' : '');
                } else if (key === 'media_type') {
                    const mediaTypeValue = intialData.media_type === 'image' ? '1' : intialData.media_type === 'audio' ? '2' : intialData.media_type === 'video' ? '3' : '';
                    setValue('media_type', mediaTypeValue);
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);

    const getMediaTypeFromRow = (mediaTypeId) => {
        switch (mediaTypeId) {
            case '1':
                return 'image';
            case '2':
                return 'audio';
            case '3':
                return 'video';
            default:
                return 'unknown';
        }
    };


    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/learningLounge/${currentId}` };
                const editResponse = await api.learningLounge.updateDetails(request, {
                    ...formData,
                    isactive: formData.status_learning === '1' ? true : false,
                    isinactive: formData.status_learning === '2' ? true : false,
                    isdeleted: formData.status_learning === '3' ? true : false,
                    menu_type_id: menuTypeId,
                    media_type: getMediaTypeFromRow(formData.media_type),
                });

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!');
                    navigate('/learningloungetable', {state: { menuTypeId, menu}});
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/learningLounge' };
                setIsfetching(true);
                setShowForm(false);
                const res = await api.learningLounge.addDetails(request, {
                    ...formData,
                    isactive: formData.status_learning === '1' ? true : false,
                    isinactive: formData.status_learning === '2' ? true : false,
                    isdeleted: formData.status_learning === '3' ? true : false,
                    menu_type_id: menuTypeId,
                    media_type: getMediaTypeFromRow(formData.media_type),
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/learningloungetable', {state: { menuTypeId, menu}});
                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)

    };

    const siteOptions = sites.map((site) => ({
        value: site.country,
        label: site.country
    }));

    const checkIsMediaRequired = (menuTypeId) => {
        if (menuTypeId === 1) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, [])


    return (
        <div className={`${styles.dataTableWrapper} mx-auto`} style={{ minHeight: '820px', height: 'auto', overflow: 'auto', paddingTop: '6.5rem' }}>
            <h4 className="my-4 pagetitle">{menu} Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        name="title"
                                        type='text'
                                        {...register('title', { required: 'Title is required' })}
                                    />
                                    {errors.title && <p style={{ color: 'red' }}>{errors.title.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Redirect URL</Form.Label>
                                    <Form.Control
                                        name="redirect_url"
                                        type="url"
                                        {...register('redirect_url')}
                                    />
                                    {errors.redirect_url && <p style={{ color: 'red' }}>{errors.redirect_url.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Media Type</Form.Label>
                                    <Form.Select
                                        name="media_type"
                                        {...register('media_type', checkIsMediaRequired(menuTypeId) ? { required: 'Media Type is required' } : {})}
                                        className="mb-3"
                                        aria-label="Default select example"
                                        placeholder="Select Type"
                                    >
                                        <option value="">Select Media Type</option>
                                        <option value="1">Image</option>
                                        <option value="2">Audio</option>
                                        <option value="3">Video</option>
                                    </Form.Select>
                                    {errors.media_type && <p style={{ color: 'red' }}>{errors.media_type.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Media URL</Form.Label>
                                    <Form.Control
                                        name="media_url"
                                        type="url"
                                        {...register('media_url', checkIsMediaRequired(menuTypeId) ? { required: 'Media URL is required' } : {})}
                                    />
                                    {errors.media_url && <p style={{ color: 'red' }}>{errors.media_url.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Publish Date</Form.Label>
                                    <Form.Control
                                        // onChange={handleChange}
                                        type="date"
                                        placeholder="Publish Date"
                                        name="publishdate"
                                        {...register('publishdate', { required: 'Publish Date is required' })}

                                    />
                                    {errors.publishdate && <p style={{ color: 'red' }}>{errors.publishdate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <Form.Label>Expiry Date</Form.Label>
                                    <Form.Control
                                        // onChange={handleChange}
                                        type="date"
                                        placeholder="Expiry Date"
                                        name="expirydate"
                                        {...register('expirydate', { required: 'Expiry Date is required' })}

                                    />
                                    {errors.expirydate && <p style={{ color: 'red' }}>{errors.expirydate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select
                                        name="status_learning"
                                        {...register('status_learning', { required: 'Status is required' })}

                                        className="mb-3"
                                        aria-label="Default select example"
                                        placeholder="Select Type"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                    </Form.Select>
                                    {errors.status_learning && <p style={{ color: 'red' }}>{errors.status_learning.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select SiteAccess</Form.Label>
                                    <Controller
                                        name='siteaccessmulti'
                                        control={control}
                                        defaultValue={[]}
                                        rules={{ required: 'SiteAccess is required' }}
                                        render={({ field }) => (
                                            <MultiSelect
                                                options={siteOptions}
                                                value={field.value}
                                                onChange={field.onChange}
                                                labelledBy="Select"
                                            />
                                        )}
                                    >
                                    </Controller>
                                    {errors.siteaccessmulti && <p style={{ color: 'red' }}>{errors.siteaccessmulti.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-end col-12 p-2'>
                                <Button variant="secondary" className='me-2' onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                                <Button type="reset" variant="secondary" className='mx-2' onClick={() => reset()}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className='ms-2'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}


        </div>
    )
}

export default LearningLoungeForm
