import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Question from './Question';
import pollData from './data';

function Survey() {
    const [answers, setAnswers] = useState({});
    const pollid=1

    const handleAnswerChange = (questionId, value) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: value,
        }));
    };

    const handleSubmit = () => {
        console.log("Submitted Answers:", pollid, answers);
        // Handle form submission logic here
    };
    return (
        <div style={{ paddingTop: '5rem', minHeight: '1050px', overflow: 'auto' }}>
            <form>
            {pollData.map((poll, index) => {
                { return(<>
                    <h5 className='border-bottom border-primary mb-3 border-3 py-2 text-5 text-primary'>{poll.poll_title}</h5>
                    {poll.questions?.map((question, index) => (
                        <Question
                            key={index}
                            question={question}
                            index={index}
                            onAnswerChange={handleAnswerChange}
                            selected={answers[question.id]} // Pass the selected value for each question
                            answers={answers}
                        />
                    ))}
                    <Button className='m-2' onClick={()=>handleSubmit()}>Submit</Button>
                    <Button className='m-2' onClick={()=>setAnswers({})}>Reset</Button>
            </>)}
            })}
            </form>
        </div>
    );
}

export default Survey;
