import React, { useRef, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from 'react-bootstrap';
import GallerySlider from './GallerySlider';

function Gallery({ data }) {
    const [show, setShow] = useState(false);
    const [initialSlide, setInitialSlide] = useState(0);
    const sliderRef = useRef(null);

    const handleClose = () => {
        setInitialSlide(0);
        setShow(false);
        setTimeout(() => {
            if (sliderRef.current) {
                sliderRef.current.slickPlay();
            }
        }, 1000);
    };

    const handleOpen = (index) => {
        setInitialSlide(index);
        setShow(true);
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    };

    const settings = {
        dots: data.length > 4,
        infinite: data.length > 4,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        rows: 2,
        slidesPerRow: 2,
    };
    
    return (
        <>
            <Modal
                className='momentsModal'
                show={show}
                size='xl'
                centered
                onHide={() => handleClose()}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <GallerySlider handleClose={handleClose} images={data} initialSlide={initialSlide} />
                </Modal.Body>
            </Modal>
            <div className="slider-container moments-captured">
                <Slider {...settings} ref={sliderRef}>
                    {data.map((item, index) => <div key={index}>
                        <img src={item?.media_url} alt={item?.title} style={{ height: '247px', margin: 'auto', cursor: 'pointer' }} onClick={() => { handleOpen(index) }} />
                    </div>)}
                </Slider>
            </div>
        </>
    );
}

export default Gallery
