import React from 'react';
import Marquee from "react-fast-marquee";
import './LearningLounge.scss';
import separatorImg from '../../assets/logo/separator.png';

function MarqueeText({ data }) {
    return (
        <div className='scrollingBar'>
            <Marquee speed={125} loop={0} pauseOnHover>
                {data.map((item, index) => {
                    return <React.Fragment key={index}>
                        {item?.redirect_url ? <span className='mx-4 px-4' style={{cursor:'pointer'}} onClick={() => { window.open(item?.redirect_url, '_blank', 'noopener') }}>{item.title}</span> : <span className='mx-4 px-4'>{item.title}</span>
                        }
                        {<img style={{ height: '10px' }} src={separatorImg} alt="separator" className='separator' />}
                    </React.Fragment>
                })}
            </Marquee>
        </div>
    )
}

export default MarqueeText
