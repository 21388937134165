import React from "react";
import Input from "./Input";

const checkboxStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
};

const Checkbox = ({ options, selected=[], onChange=()=>{} }) => {
    return (
        <div style={checkboxStyle}>
            {options.map((option, key) => (
                <Input
                    key={key}
                    type="checkbox"
                    option={option}
                    checked={selected.includes(option)}
                    onChange={(e) => {
                        const updatedSelection = e.target.checked
                            ? [...selected, option]
                            : selected.filter((item) => item !== option);
                        onChange(updatedSelection);
                    }} />
            ))}
        </div>
    );
};

export default Checkbox;
