import React from 'react';
import './PrivacyPolicy.scss';


function PrivacyPolicy() {
  return (
    <div className='privacyContainer'>
      <h5 className='pagetitle'>Privacy Policy</h5>
      <p className='privacyText'>At Agilisium Consulting Pvt, LLC, and Inc we are committed to protecting the privacy and confidentiality of our employees' personal information and internal communications. This Privacy Policy outlines how we collect, use, disclose, and safeguard information obtained through Agilisium people Hub.</p>
      <p className='privacyText'><b>1. Information We Collect:</b></p>
      <p className='privacyText'>Personal Information: We may collect personal information from employees, including but not limited to names, contact details, job titles, and department information.</p>
      <p className='privacyText'>Communication Data: We may collect data generated from Agilisium people hub channels, including emails, instant messages, posts, comments, and internal collaboration platforms.</p>
      <p className='privacyText'><b>2. How We Use Information:</b></p>
      <p className='privacyText'>Internal Communication: We use personal information and communication data for the purpose of facilitating internal communication among employees, teams, and departments.</p>
      <p className='privacyText'>Business Operations: We may use information collected for internal administrative purposes, such as managing employee accounts, providing access to company resources, and facilitating collaboration.</p>
      <p className='privacyText'><b>3. Information Sharing and Disclosure:</b></p>
      <p className='privacyText'>Internal Sharing: Personal information and communication data may be shared internally with authorized employees and departments on a need-to-know basis.</p>
      <p className='privacyText'>Third-Party Service Providers: We may engage third-party service providers to assist with internal communication tools and platforms. These providers are contractually obligated to protect the confidentiality and security of information.</p>
      <p className='privacyText'><b>4. Data Security:</b></p>
      <p className='privacyText'>We implement appropriate technical and organizational measures to safeguard personal information and communication data against unauthorized access, disclosure, alteration, or destruction.</p>
      <p className='privacyText'>Access controls, encryption, and regular security assessments are utilized to protect data integrity and confidentiality.</p>
      <p className='privacyText'><b>5. Data Retention:</b></p>
      <p className='privacyText'>We retain personal information and communication data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws and regulations.</p>
      <p className='privacyText'>Upon termination of employment or as per company policies, we may securely dispose of or anonymize personal information and communication data.</p>
      <p className='privacyText'><b>6. Employee Rights:</b></p>
      <p className='privacyText'>Employees have the right to access, update, and correct their personal information held by the company.</p>
      <p className='privacyText'>Employees may request the deletion of personal information in accordance with applicable laws and regulations, subject to certain exceptions.</p>
      <p className='privacyText'><b>7. Updates to Privacy Policy:</b></p>
      <p className='privacyText'>We may update this Privacy Policy from time to time to reflect changes in our internal communication practices or legal requirements. Any updates will be communicated to employees through appropriate channels.</p>
      <p className='privacyText'><b>8. Contact Information:</b></p>
      <p className='privacyText'>For questions or concerns regarding this Privacy Policy or our internal communication practices, employees may contact Agilisium's HR Department.</p>
      <p className='privacyText'><b>9. User Authentication and Authorization:</b></p>
      <p className='privacyText'>User authentication mechanisms, such as passwords or multi-factor authentication, are employed to ensure that only authorized employees have access to the internal communication portal. Access to sensitive information is restricted based on employees' roles and responsibilities within the organization.</p>
      <p className='privacyText'><b>10. Monitoring and Compliance:</b></p>
      <p className='privacyText'>We may monitor the use of the internal communication portal for compliance with company policies and legal requirements. Monitoring activities are conducted in accordance with applicable laws and regulations, and employees are informed about the purposes and extent of monitoring.</p>
      <p className='privacyText'><b>11. Cookies and Tracking Technologies:</b></p>
      <p className='privacyText'>We may use cookies and similar tracking technologies to enhance the functionality of the internal communication portal and improve user experience. Employees are provided with options to manage cookie preferences, where applicable, in accordance with our Cookie Policy.</p>
      <p className='privacyText'>By using our internal communication channels, employees acknowledge and consent to the collection, use, and disclosure of their personal information as described in this Privacy Policy.</p>
    </div>
  )
}

export default PrivacyPolicy
