// import * as http from './http';
import ApiService from './service';
import * as http from './http';

export class FlashNewsService extends ApiService {
    async addDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async getDetails(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async updateFlashNews(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async softDeleteRecord(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const data = {};
        return http.patch(url, data, this.store);
    }
}
