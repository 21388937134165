import React from 'react'
import { useEffect,useState,useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete, MdTableRows } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { formatDate } from '../../utils/formatDate';
import { useSelector } from 'react-redux';

const ManageSurvey = () => {

  const [data, setData] = useState([])
  const [isfetching, setIsfetching] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [intialValues, setIntialValues] = useState();

  const navigate = useNavigate()

  const authState = useSelector((state) => state.authUser);
  const role_id = authState?.profile?.role?.role_id;

  const userCountry = localStorage.getItem('userCountry');

  const {
      setValue
  } = useForm();

  const fetchPollMasterRecords = async ()=>{
    try {
        setIsfetching(true);
        const request = {resource: 'api/pollmaster'};
        const response = await api.poll.getPollMasterDetails(request);
        const data =  response.data.data.res;
        const filteredData = data?.filter(item => (item.isdeleted === false)) // Filter Active items
        setData(filteredData);
    } catch (error) {
        console.log(error.message)
    } finally{
        setIsfetching(false)
    }
}

useEffect(()=>{
    fetchPollMasterRecords();
},[])


  const getStatusFromRow = (row) => {
    const isActive = row.isactive ? "Active" : "Inactive";
    // Concatenate the statuses with a comma
    const status = [isActive].filter(Boolean).join(", ");
    return status || "Unknown";
  };

  const columns = [
    {
        dataField: 'name',
        text: 'Title',
        sort: true,
        formatter: (cellContent, row) => {
            return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
        }
    },
    {
        dataField: 'description',
        text: 'Description',
        sort: true,
        formatter: (cellContent, row) => {
            return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
        }
    },
    {
        dataField: 'startdate',
        text: 'Start Date',
        sort: true,
        formatter: (cellContent, row) => {
            return formatDate(row?.startdate);
        }
    },
    {
        dataField: 'enddate',
        text: 'End Date',
        sort: true,
        formatter: (cellContent, row) => {
            return formatDate(row?.enddate);
        }
    },   
    {
        dataField: 'isactive',
        text: 'Status',
        sort: true,
        formatter: (cellContent, row) => {
            const status = getStatusFromRow(row);
            return status;
        }
    
    },
    {
        dataField: 'managequestions',
        text: 'Mange Questions',
        formatter: (cell, row) => (
            <>
                <MdTableRows size={20} title={'Add/Edit Questions'} className='ms-1' onClick={() => handleRows(row)} />
            </>
        ),
        sort: false,
    },
    {
        dataField: 'action',
        text: 'Actions',
        formatter: (cell, row) => (
            <>
                <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
            </>
        ),
        sort: false,
    },
];

const handleDeleteClick = async (row) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this record?');
    if (!confirmDelete) return;
    try {
        setIsfetching(true)
        const request = { resource: `api/pollmaster/${row.id}` };
        const response = await api.poll.softDeletePollMasterRecord(request)
        if (response.data.status === 200) {
            toastr.success('Record deleted successfully!');
            window.location.reload(); // Refresh the data after deletion
        } else {
             toastr.error('Failed to delete the record.');
        }
    } catch (error) {
        console.log(error.message)
    } finally{
        setIsfetching(false);
    }
}

    const handleRows = (row) => {
      setIntialValues(row)
      setCurrentId(row.id);
      navigate('/surveyQuestionsForm', {state:{currentpollid:row.id, intialData:row}})
    };

    const handleEdit = (row) => {
        console.log('ROW', row)
        const hasNullField = [
            row.name,
            row.description,
            row.startdate,
            row.enddate,
        ].some(field => field === null || field === '');

    // If any field is null, show error and return from the function
    if (hasNullField) {
        toastr.error('Missing Required Fields!');
        return;
    }
      setIntialValues(row)
      setCurrentId(row.id);
      navigate('/surveyMasterForm', {state:{data, currentId:row.id, intialData:row, isEdit: true}})
    };

    const handleNew = () =>{
      navigate('/surveyMasterForm', {state:{data, currentId, isEdit: false}})
    }


    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, [])

  return (
    <div className={styles.fameContainer} style={{ height: '1150px' }}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
            <h4 className="my-4 managePageTitle">Manage Survey</h4>
            {!isfetching && <div className={`${styles.filtersWrap} d-flex align-items-center  mt-3 mb-1 justify-content-end`}>
                 {/* <MultiSelect
                    className={`${styles.multiSelect} me-3`}
                    options={[
                        { value: 'GLOBAL', label: 'GLOBAL' }, // Option for selecting all locations
                        ...sites.map(loc => ({ value: loc.country, label: loc.country })) // Options for individual locations
                    ]}
                    value={selectedLocations}
                    onChange={handleLocationChange}
                    labelledBy='Select Location'
                    disableSearch={true}
                    hasSelectAll={false}
                    shouldCloseOnSelect={false}
                    selectAllLabel='GLOBAL'
                    overrideStrings={{
                        selectSomeItems: "Select Location", // Custom placeholder text
                    }}
                    /> */}
                <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                     Add New
                </Button>

            </div>}
            </div>
        {isfetching && <ContainerLoader/>}
        <div className="walloffame_wrap mb-5">
            {data && data?.length > 0 && !isfetching &&(<div className='row'>
                <div className='col-12'>
                    <div className={`${styles.dataTableWrapper} mx-auto`}>
                        <DataTables records={data} columns={columns}/>
                    </div>   
                </div>
            </div>)}

            {data?.length === 0 && !isfetching && <Alert variant='danger' className='mx-auto' style={{width:'90%'}}>No Records Found!!!</Alert>}
          
        </div>
        </div>
  )
}

export default ManageSurvey