import React, { useEffect } from 'react';
import { BarChart, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, Rectangle, Brush } from 'recharts';

const data = [
  { name: 'Jan', uv: 400, pv: 100, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 150, amt: 2210 },
  { name: 'Mar', uv: 200, pv: 280, amt: 2290 },
  { name: 'Apr', uv: 278, pv: 908, amt: 2000 },
  { name: 'May', uv: 189, pv: 180, amt: 2181 },
  { name: 'Jun', uv: 239, pv: 280, amt: 2500 },
  { name: 'Jul', uv: 349, pv: 230, amt: 2100 },
];


const SimpleLineChart = () => {

  return (
    <div style={{ paddingTop: '10rem', minHeight: '1050px', overflow: 'auto' }}>
      <div style={{ height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" angle={-45} textAnchor='end'/>
            <YAxis />
            <Tooltip />
            <Legend layout='horizontal' verticalAlign='top' align='center'/>
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div style={{ height: '300px', marginTop: '2rem' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" angle={-45} textAnchor='end'/>
            <YAxis />
            <Tooltip />
            <Legend layout='horizontal' verticalAlign='top' align='center'/>
            <Bar
              dataKey="uv"
              fill="#B3CDAD"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Bar
              dataKey="pv"
              fill="#FF5F5E"
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SimpleLineChart;
