import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ClientDetailsForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const industry = location.state?.industry || {}
    const currentId = location.state?.currentId || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'isactive') {
                    setValue('status', intialData.isactive ? '1' : '2');
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);


    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false);
                const request = { resource: `api/clientsinformation/${currentId}` };
                const editResponse = await api.clientsinformation.updateDetails(request, {
                    ...formData,
                    isactive: formData.status === '1' ? true : false,
                })

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/manageclientdetails')
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/clientsinformation' };
                setIsfetching(true);
                setShowForm(false);
                const res = await api.clientsinformation.addDetails(request, {
                    ...formData,
                    isactive: formData.status === '1' ? true : false,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/manageclientdetails')

                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)

    };

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])


    return (
        <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
            <h4 className="my-4 pagetitle">Client Details Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Client Name</Form.Label>
                                    <Form.Control
                                        name="client_name"
                                        type='text'
                                        {...register('client_name', { required: 'Client Name is required' })}
                                    />
                                    {errors.client_name && <p style={{ color: 'red' }}>{errors.client_name.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select industry</Form.Label>
                                    <Form.Select
                                        name="industry_type_id"
                                        {...register('industry_type_id', { required: 'Industry is required' })}

                                        className="mb-3"
                                        aria-label="Default select example"
                                        placeholder="Select Client"
                                    >
                                        <option value="">Select industry</option>
                                        {industry.map((item, index) => <option key={index} value={item.id}>{item.industry_title}</option>)}
                                    </Form.Select>
                                    {errors.industry_type_id && <p style={{ color: 'red' }}>{errors.industry_type_id.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-12 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows={3}
                                        {...register('description', { required: 'Description is required' })}
                                    />
                                    {errors.description && <p style={{ color: 'red' }}>{errors.description.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Redirect URL</Form.Label>
                                    <Form.Control
                                        name="redirect_url"
                                        type="url"
                                        {...register('redirect_url', { required: 'Redirect URL is required' })}
                                    />
                                    {errors.redirect_url && <p style={{ color: 'red' }}>{errors.redirect_url.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Image URL</Form.Label>
                                    <Form.Control
                                        name="image_url"
                                        type="url"
                                        {...register('image_url', { required: 'Image URL is required' })}
                                    />
                                    {errors.image_url && <p style={{ color: 'red' }}>{errors.image_url.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        {...register('status', { required: 'Status is required' })}

                                        className="mb-3"
                                        aria-label="Default select example"
                                        placeholder="Select Type"
                                    >
                                        <option value="">Select Status</option>
                                        <option value='1'>Active</option>
                                        <option value='2'>Inactive</option>
                                    </Form.Select>
                                    {errors.status && <p style={{ color: 'red' }}>{errors.status.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-end col-12 p-2'>
                                <Button variant="secondary" className='me-2' onClick={() => navigate('/manageclientdetails')}>
                                    Cancel
                                </Button>
                                <Button type="reset" variant="secondary" className='mx-2' onClick={() => reset()}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className='ms-2'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ClientDetailsForm;
