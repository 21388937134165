import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import './LearningLounge.scss'
import MarqueeText from './MarqueeText'
import QuickLinksCard from './QuickLinksCard'
import ProgramsAvailable from './ProgramsAvailable'
import Gallery from './Gallery'
import KnowledgeNuggets from './KnowledgeNuggets'
import VideoContainer from './VideoContainer'
import FeedbackUs from './FeedbackUs'
import api from '../../api'
import ContainerLoader from '../loader/container-loader'

function LearningLounge() {
    const [data, setData] = useState([]);
    const [isfetching, setIsfetching] = useState(false);

    const fetchData = async () => {
        try {
            setIsfetching(true)
            const request = { resource: 'api/learningLounge' };
            const response = await api.learningLounge.getlearningLounge(request);
            let learningData = response.data.data.res;
            let filteredData = learningData.filter(record => record?.isactive === true);
            setData(filteredData);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsfetching(false)
        }
    }

    function filterByMenuTypeId(alldata, menuTypeId) {
        return alldata.filter(item => item.menu_type_id === menuTypeId);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div style={{ minHeight: '820px', height: 'auto', overflow: 'auto', paddingTop: '5rem', paddingBottom: '1rem' }}>
            {isfetching ? <div className='loaderMain'><ContainerLoader /></div> :
                <>
                    <Row>
                        <MarqueeText data={filterByMenuTypeId(data, 1)} />
                        <Col md={7} className='' style={{ height: '500px' }}>
                            <ProgramsAvailable data={filterByMenuTypeId(data, 2)} />
                        </Col>
                        <Col md={5} className='' style={{ height: '500px' }}>
                            <Gallery data={filterByMenuTypeId(data, 3)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className=''>
                            <div className='quickLinksHeader'>Quick Links</div>
                            <div className='d-flex px-5 pb-3 flex-wrap'>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 4)} />
                                </div>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 5)} />
                                </div>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 6)} />
                                </div>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 7)} />
                                </div>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 8)} />
                                </div>
                                <div className='quickLinksCard'>
                                    <QuickLinksCard data={filterByMenuTypeId(data, 9)} />
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='mt-5'>
                            <Row className='d-flex justify-content-end' style={{ marginTop: '40px'}}>
                                <Col md={5} className='knowledgeCard'>
                                    <KnowledgeNuggets playlist={filterByMenuTypeId(data, 10)} />
                                </Col>
                                <Col md={5} className='knowledgeCard'>
                                    <VideoContainer playlist={filterByMenuTypeId(data, 11)} />
                                </Col>
                                <Col md={2} style={{margin: '0px 10px 0px 10px'}}>
                                    <FeedbackUs />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>}
        </div>
    )
}

export default LearningLounge
