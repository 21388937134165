import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AudioPlayerComponent from './AudioPlayerComponent';
import knowledgeBites from "../../assets/logo/learningLounge/knowledgeBites.png";

function KnowledgeNuggets({ playlist }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Modal
        className='momentsModal'
        show={show}
        size='xl'
        centered
        onHide={() => setShow(false)}
        keyboard={false}  // Prevents closing on escape key press
      >
        <Modal.Body>
          <AudioPlayerComponent setShow={setShow} playlist={playlist} />
        </Modal.Body>
      </Modal>
      <div style={{ height: '100%', cursor: 'pointer' }} onClick={() => setShow(true)}>
        <img src={knowledgeBites} alt='knowledgeBites' style={{width:'250px', height: 'auto', objectFit:'contain'}} />
      </div>
    </>
  )
}

export default KnowledgeNuggets

