// import * as http from './http';
import ApiService from './service';
import * as http from './http';

export class PollService extends ApiService {
    async addPollMasterDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async addPollQuestions(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async addPollQuestionsOptions(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async getPollMasterDetails(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async updatePollMaster(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async updatePollQuestions(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async updateQuestionsOptions(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }
    
    async softDeletePollMasterRecord(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const data = {};
        return http.patch(url, data, this.store);
    }
    
    async getPollQuestionOptionTypes(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
        .get(url, this.store)
        .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getPollQuestionsByPollID(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
        .get(url, this.store)
        .catch((err) => console.log(err));
        return { data: response.data };
    }

    async deletePollQuestionRecord(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.remove(url, this.store);
    }

    async softDeletePollQuestionRecord(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const data = {};
        return http.patch(url, data, this.store);
    }

}
