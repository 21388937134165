import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { MdClose } from "react-icons/md";

function VideoPlayer({ setShow, playlist }) {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

    return (
        <div>
            <div className="d-flex justify-content-end">
                <Button className="btn btn-sm btn-primary float-right" onClick={() => { setShow(false) }}><MdClose /></Button>
            </div>
            <Row>
                <Col md={9}>
                    <div>
                        <ReactPlayer width='100%' url={playlist[currentTrackIndex]?.media_url} controls muted={true} playing={true} />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          </div>

                    </div>
                </Col>
                <Col md={3}>
                        <div className='d-flex justify-content-between mt-2' style={{ backgroundColor: '#000', color: '#fff', borderRadius: '5px 5px 0px 0px'}}>
                            <div className='p-3 text-center'>Queue</div>
                        </div>
                        <ul className="playlist" style={{overflow:'scroll', height:'300px'}}>
                            {playlist.map((track, index) => (
                                <li
                                    key={index}
                                    onClick={() => setCurrentTrackIndex(index)}
                                    className={currentTrackIndex === index ? 'active' : ''}
                                >
                                    {track.title}
                                </li>
                            ))}
                        </ul>
                </Col>
            </Row>
        </div>
    )
}

export default VideoPlayer
