import React from 'react';
import User from '../../containers/user/User';

export default function AddUser() {
  return (
    <div style={{paddingTop:'6rem', minHeight: '1150px', marginBottom: '1rem'}}>
      <User />
    </div>
  );
}
