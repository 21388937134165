import React from 'react'
import { useEffect,useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm,Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import './ManageAgilisiumInsights.scss';
import {MultiSelect} from 'react-multi-select-component';
import { useSelector } from 'react-redux';

const InsightsForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const currentId = location.state?.currentId || {};
    const sites = location.state?.sites || {};
    const isEdit = state ? state.isEdit : false;
    
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;    

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'publishdate') {
                    const formattedPublishDate = intialData.publishdate ? new Date(intialData.publishdate).toISOString().split('T')[0] : null;
                    setValue('publishdate', formattedPublishDate);
                } else if (key === 'expirydate') {
                    const formattedExpirydate = intialData.expirydate ? new Date(intialData.expirydate).toISOString().split('T')[0] : null;
                    setValue('expirydate', formattedExpirydate);
                } else if (key === 'isactive' || key === 'isinactive' || key === 'isarchived') {
                    setValue('status_insights', intialData.isactive ? '1' : intialData.isinactive ? '2' : intialData.isarchived ? '3' : '');
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);

    const handleData = async (formData) => {    
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/insights/${currentId}` };
                const editResponse = await api.insights.updateDetails(request, {
                    ...formData,
                    isactive: formData.status_insights === '1' ? true : false,
                    isinactive: formData.status_insights === '2' ? true : false,
                    isarchived: formData.status_insights === '3' ? true : false,
                })                    
                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/manageinsights')
                }                    
            } catch (error) {
                console.log(error.message)
            } finally {
                setIsfetching(false);
            }
        } else {
            try {
                const request = { resource: 'api/insights' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.insights.addDetails(request, {
                    ...formData,
                    isactive: formData.status_insights === '1' ? true : false,
                    isinactive: formData.status_insights === '2' ? true : false,
                    isarchived: formData.status_insights === '3' ? true : false,
                });
    
                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/manageinsights')
                    
                } else {
                    toastr.error('Error', res.data.message);
                }
                
            } catch (error) {
                console.log(error.message);
            } finally {
                setIsfetching(false);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true);
    };

    const siteOptions = sites.map((site) => ({
        value: site.country,
        label: site.country
    }));

    useEffect(() => {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, [])

return (
    <>
        <Modal show={isfetching} className='loader'>
            <ContainerLoader height={'auto'} />
        </Modal>
        <div className={`dataTableWrapper formWrapper mx-auto justify-content-between p-2`}>
        <h4 className="pagetitle">Agilisium Insights Form</h4>
            <div>
                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='w-sm-100 mx-auto' style={{width: '90%'}}>
                                <Form onSubmit={handleSubmit(handleData)} className='d-flex flex-wrap justify-content-between'>    
                                    <div className='col-12 col-md-6 p-2'>
                                        <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title..."
                                            name="title"
                                            maxLength="255"
                                            {...register('title', { required: 'Title is required' })}
                                        />
                                        {errors.title && <p style={{color:'red'}}>{errors.title.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Image URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Paste URL..."
                                            name="imageurl"
                                            maxLength="255"
                                            {...register('imageurl', { required: 'Image URL is Required' })}
                                        />
                                        {errors.imageurl && <p style={{color:'red'}}>{errors.imageurl.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                        <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Redirect URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Paste URL..."
                                            name="redirecturl"
                                            maxLength="255"
                                            {...register('redirecturl', { required: 'Redirect URL is Required' })}
                                        />
                                        {errors.redirecturl && <p style={{color:'red'}}>{errors.redirecturl.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                        <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            name="description"
                                            as="textarea"
                                            rows={3}
                                            maxLength={500}
                                            {...register('description', { required: 'Description is required' })}
                                        />
                                        {errors.description && <p style={{color:'red'}}>{errors.description.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Publish Date</Form.Label>
                                        <Form.Control
                                            // onChange={handleChange}
                                            type="date"
                                            placeholder="Publish Date"
                                            name="publishdate"
                                            {...register('publishdate', { required: 'Publish Date is required' })}
                                        />
                                        {errors.publishdate && <p style={{color:'red'}}>{errors.publishdate.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                        <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Expiry Date</Form.Label>
                                        <Form.Control
                                            // onChange={handleChange}
                                            type="date"
                                            placeholder="Expiry Date"
                                            name="expirydate"
                                            {...register('expirydate', { required: 'Expiry Date is required' })}
                                        />
                                        {errors.expirydate && <p style={{color:'red'}}>{errors.expirydate.message}</p>}
                                    </Form.Group>
                                    </div>
                                    <div className='col-12 col-md-6 p-2'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">

                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            name="status_insights"
                                            {...register('status_insights', { required: 'Status is required' })}

                                            className="mb-3"
                                            aria-label="Default select example"
                                            placeholder="Select Type"
                                        >
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                            <option value="3">Archived</option>
                                        </Form.Select>
                                        {errors.status_insights && <p style={{ color: 'red' }}>{errors.status_insights.message}</p>}
                                    </Form.Group>
                                    </div>
                                    {/* <div className='col-12 col-md-6 p-2'>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Site Access</Form.Label>
                                        <Form.Select
                                            name="siteaccess"
                                            {...register('siteaccess', { required: 'siteAccess is required' })}

                                            className="mb-3"
                                            aria-label="Default select example"
                                            placeholder="Select Type"
                                        >

                                            <option value="">Select SiteAccess</option>
                                            {sites?.map((opt, index) => {
                                                return <option key={index} value={opt.country}>{opt.country}</option>
                                            })}
                                        </Form.Select>
                                        {errors.siteaccess && <p style={{ color: 'red' }}>{errors.siteaccess.message}</p>}

                                    </Form.Group>
                                    </div> */}
                                     <div className='col-12 col-md-6 p-2'>
                                        <Form.Group>
                                            <Form.Label>Select SiteAccess</Form.Label>
                                            <Controller
                                                    name='siteaccessmulti'
                                                    control={control}
                                                    defaultValue={[]}
                                                    rules={{ required: 'SiteAccess is required' }}
                                                        render={({ field }) => (
                                                            <MultiSelect
                                                                options={siteOptions}
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                labelledBy="Select"
                                                            />
                                                        )}
                                                >
                                            </Controller>
                                            {errors.siteaccessmulti && <p style={{color:'red'}}>{errors.siteaccessmulti.message}</p>}
                                            </Form.Group>
                                    </div>
                                    <div className='d-flex justify-content-end col-12 p-2'>
            <Button variant="secondary" className='me-2' onClick={()=> navigate('/manageinsights')}>
                 Cancel
            </Button>
            <Button type="reset" variant="secondary" className='mx-2' onClick={()=> reset()}>
                Reset
            </Button>
            <Button type="submit" variant="primary" className='ms-2'>
                Save
            </Button>
            </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);


};

export default InsightsForm;