const pollData =
  [
    {
    poll_ID: 1,
    poll_title: 'HR Survey : Global Work station...',
    questions: [
      {
        id: 1,
        type: 'text',
        title: 'What\'s your name? - Textbox'
      },
      {
        id: 2,
        type: 'radio',
        alignAnswers: 'row',
        name: 'gender',
        title: 'Your gender? - Radio',
        options: ['Male', 'Female']
      },
      {
        id: 3,
        type: 'date',
        title: 'What date of birth? - Date'
      },
      {
        id: 4,
        type: 'select',
        title: 'Your status: - Select',
        options: [
          'Single', 'Married'
        ]
      },
      {
        id: 5,
        type: 'radio',
        alignAnswers: 'row',
        name: 'star-rating',
        title: 'Ratings? - radio',
        options: ['Ok (1/3)', 'Fine (2/3)', 'So good (3/3)']
      },

      {
        id: 6,
        type: 'textarea',
        title: 'Comments: - Text area'
      },
      {
        id: 7,
        type: 'multiselect',
        title: 'Favourite food: - MultiSelect',
        options: ['Bread','Pizza', 'Dog', 'Cat']
      },
      {
        id: 8,
        type: 'checkbox',
        title: 'Favourite food: - checkbox',
        options: ['Bread', 'Pizza', 'Coke', 'Cake']
      },
      {
        id: 9,
        type: 'date',
        title: 'What date of Join? - Date'
      },]
  },
]

export default pollData;