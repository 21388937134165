import React from 'react'
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm, Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AddPolicyForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const data = location.state?.data || {};
    const currentId = location.state?.currentId || {};
    const types = location.state?.types || {};
    const sites = location.state?.sites || {};
    const isEdit = state ? state.isEdit : false;

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'publishdate') {
                    const formattedPublishDate = intialData.publishdate ? new Date(intialData.publishdate).toISOString().split('T')[0] : null;
                    setValue('publishdate', formattedPublishDate);
                } else if (key === 'expirydate') {
                    const formattedExpirydate = intialData.expirydate ? new Date(intialData.expirydate).toISOString().split('T')[0] : null;
                    setValue('expirydate', formattedExpirydate);
                } else if (key === 'isactive' || key === 'isdeleted') {
                    setValue('status_policy', intialData.isactive ? '1' : intialData.isdeleted ? '2' : '');
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);

    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/policies/${currentId}` };
                const editResponse = await api.policiesservice.updatePolicy(request, {
                    ...formData,
                    isactive: formData.status_policy === '1' ? true : false,
                    isdeleted: formData.status_policy === '2' ? true : false,
                })

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/managePolicy')
                }
                setIsfetching(false);

            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const request = { resource: 'api/policies' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.policiesservice.addPolicy(request, {
                    ...formData,
                    isactive: formData.status_policy === '1' ? true : false,
                    isdeleted: formData.status_policy === '2' ? true : false,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/managePolicy')

                } else {
                    toastr.error('Error', res.data.message);
                }

            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)

    };
    const siteOptions = sites.map((site) => ({
        value: site.country,
        label: site.country
    }));

    const getPolicyTypeName = (typeId) => {
        switch (typeId) {
            case 1:
                return 'HR Policies';
            case 2:
                return 'IT Policies';
            case 3:
                return 'TA Policy';
            case 4:
                return 'Benefits';
            default:
                return 'Unknown Policy Type';
        }
    };

    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []);

    return (
        <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
            <h4 className="my-4 pagetitle">Policies and Benefits Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto' style={{ width: '90%' }}>
                        <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Policy Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter policy name" {...register('policyname', { required: 'Policy Name is required' })} />
                                    {errors.policyname && <p className="text-danger">{errors.policyname.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Policy Document URL</Form.Label>
                                    <Form.Control type="text" placeholder="Enter document URL" {...register('policydocumenturl', { required: 'Policy Document URL is required' })} />
                                    {errors.policydocumenturl && <p className="text-danger">{errors.policydocumenturl.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Policy Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter policy description" {...register('policydescription', { required: 'Policy Description is required' })} />
                                    {errors.policydescription && <p className="text-danger">{errors.policydescription.message}</p>}
                                </Form.Group>
                            </div>
                            {/* <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Policy Tile URL</Form.Label>
                                    <Form.Control type="text" placeholder="Enter tile URL" {...register('policytileurl')} />
                                </Form.Group>
                            </div> */}
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Publish Date</Form.Label>
                                    <Form.Control type="date" {...register('publishdate', { required: 'Publish Date is required' })} />
                                    {errors.publishdate && <p className="text-danger">{errors.publishdate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Expiry Date</Form.Label>
                                    <Form.Control type="date" {...register('expirydate', { required: 'Expiry Date is required' })} />
                                    {errors.expirydate && <p className="text-danger">{errors.expirydate.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Policy Type</Form.Label>
                                    <Form.Select
                                        name="policytypeid"
                                        {...register('policytypeid', { required: 'Type is required' })}

                                        className=""
                                        aria-label="Default select example"
                                        placeholder="Select Type"
                                    >
                                        <option value="">Select Type</option>
                                        {types?.map((opt, index) => {
                                            return <option key={index} value={opt.id}>{getPolicyTypeName(opt.id)}</option>
                                        })}
                                    </Form.Select>
                                    {errors.policytypeid && <p style={{ color: 'red' }}>{errors.policytypeid.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select SiteAccess</Form.Label>
                                    <Form.Select
                                        name='siteaccess'
                                        {...register('siteaccess', { required: 'SiteAccess is required' })}

                                        className=""
                                        aria-label="Default select example"
                                        placeholder="Select SiteAccess"
                                    >
                                        <option value="">Select SiteAccess</option>
                                        <option value="1">Offshore</option>
                                        <option value="2">Onshore</option>
                                        <option value="3">Nearshore</option>
                                    </Form.Select>
                                    {errors.siteaccess && <p style={{ color: 'red' }}>{errors.siteaccess.message}</p>}
                                </Form.Group>
                            </div>


                            <div className='col-12 col-md-6 p-2'>
                                <Form.Group>
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select
                                        name="status_policy"
                                        {...register('status_policy', { required: 'Status is required' })}

                                        className=""
                                        aria-label="Default select example"
                                        placeholder="Select Status"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Deleted</option>
                                    </Form.Select>
                                    {errors.status_policy && <p style={{ color: 'red' }}>{errors.status_policy.message}</p>}
                                </Form.Group>
                            </div>
                            <div className='d-flex justify-content-end col-12 p-2'>
                                <Button variant="secondary" className="me-2" onClick={() => navigate('/ManagePolicy')}>
                                    Cancel
                                </Button>
                                <Button type="reset" variant="secondary" className="mx-2" onClick={() => reset()}>
                                    Reset
                                </Button>
                                <Button type="submit" variant="primary" className="ms-2">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>}


        </div>
    )
}

export default AddPolicyForm;
