import React from 'react';
import './ClientSummary.css';

function ClientCard({ data }) {
    return (
        <div className='clientCard col-11 col-md-3'>
            <div className='d-flex justify-content-center'>
            <img src={data.image_url} alt={data?.client_name} />
            </div>
            <p className='clientName'>{data?.client_name}</p>
            <p className='clientDescription'>{data?.description}</p>
            <a href={data?.redirect_url} target='_blank'>{data?.redirect_url}</a>
        </div>
    )
}

export default ClientCard
