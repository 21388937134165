import ApiService from './service';
import * as http from './http';

export class ZohoService extends ApiService {

    async getZohoToken() {
        const url = `${this.apiDomain}/api/zoho`;

        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        // return { data: response.data };
        return response.data;
    }

    async getZohoEmployee() {
        const url = `${this.apiDomain}/api/zoho/employee`;

        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return response.data;
    }

    async getEmployeeByEmail(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getAllEmployees(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getEmployessByReport(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getEmployeeAttendence(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getClientSummary(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getAnniversaryEmployees(request) {
        const {resource} = request;
        const url = `${this.apiDomain}/${resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }
    async getEmployeesByBirthday(request) {
        const {resource} = request;
        const url = `${this.apiDomain}/${resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }
    async getHolidays(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getLocation(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async checkInOut(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }
    
}