import { useEffect, useState } from 'react';
import api from '../../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import ClientCard from './ClientCard';
import './ClientSummary.css'

const ClientSummary = () => {

  const [data, setData] = useState([]);
  const [industry, setIndusrty] = useState([]);
  const [isfetchingClients, setIsfetchingClients] = useState(false);
  const [isfetchingIndusrty, setIsfetchingIndusrty] = useState(false);

  const fetchClientRecords = async () => {
    try {
      setIsfetchingClients(true);
      const request = { resource: 'api/clientsinformation' };
      const response = await api.clientsinformation.getDetails(request)
      const filteredData = response.data.data.res.filter(item => (item.isdeleted === false));
      setData(groupByIndustryId(filteredData));
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsfetchingClients(false);
    }
  }

  const fetchIndustryDetails = async () => {
    try {
      setIsfetchingIndusrty(true);
      const request = { resource: 'api/industry' };
      const response = await api.clientsinformation.getIndustryDetails(request);
      setIndusrty(response.data.data.res);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsfetchingIndusrty(false);
    }
  }

  const getTitleById = (id) => {
    const industryName = industry?.find(item => item?.id === id);
    return industryName ? industryName?.industry_title : 'Unknown';
  };

  const groupByIndustryId = (rawData) => {
    if (rawData.length > 0) {
      const groupedData = rawData.reduce((acc, current) => {
        const { industry_type_id } = current;
        const industry = acc.find(item => item.industry_type_id === industry_type_id);

        if (industry) {
          industry.clients.push(current);
        } else {
          acc.push({
            industry_type_id,
            clients: [current]
          });
        }
        return acc;
      }, []);
      // Sort the grouped data by industry_type_id
      groupedData.sort((a, b) => a.industry_type_id - b.industry_type_id);
      // Sort the children by client_name
      groupedData.forEach(group => {
        group.clients.sort((a, b) => a.client_name.localeCompare(b.client_name));
      });
      
      return groupedData;
    } else return [];
  }

  useEffect(() => {
    fetchIndustryDetails();
    fetchClientRecords();
  }, [])

  return (
    <div>
      <div className='clientSummary'>
        <h3 className='pageTitle my-4 ms-4'>Clients Information</h3>
        {(isfetchingClients || isfetchingIndusrty) ? <ContainerLoader /> :
          <>
            {data.length > 0 ? data?.map((client, index) => {
              return <div key={index}>
                <h3 className='clientTitle my-4 ms-4'>{getTitleById(client.industry_type_id)}</h3>
                <div className='d-flex flex-wrap justify-content-around mb-5'>
                  {client?.clients?.map((clientInfo, index) => <ClientCard key={index} data={clientInfo} />)}
                </div>
              </div>
            }) : <></>}
          </>}
      </div>
    </div>
  )
}

export default ClientSummary
